import i18next from "i18next";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Button, Container, Form, Message, Progress } from "semantic-ui-react";
import withError from "./hocs/withError";
import withLabel from "./hocs/withLabel";
import withContainer from "./hocs/withContainer";
import VideoPlayer from "../../components/media/VideoPlayer";
import { useParams } from "react-router-dom";
import useUserMedia from "../../hooks/useUserMedia";
import { withTranslation } from "react-i18next";
import EnhancedVideoPlayer from "../../components/media/EnhancedVideoPlayer/EnhancedVideoPlayer";
import UserContext from "../../context/UserContext";
import { typeHelper } from "atom5-branching-questionnaire";
import GroupPermission from "../../GroupPermission";
import PermissionsService from "../../services/PermissionsService";
import SubjectService from "../../SubjectService";
import AttachmentDownload from "../display/AttachmentDownload";

const MonitoredVideo = ({ t, question, value, changeValue, isReadOnly, isInSideView }) => {
  const forceUseEnhancedPlayer = typeHelper.parseBool(question.config?.useEnhancedPlayer);
  const [useEnhancedPlayer, setUseEnhancedPlayer] = useState(forceUseEnhancedPlayer);
  const user = useContext(UserContext);
  const [hasAllowSubjectMediaAudioPermission, setHasAllowSubjectMediaAudioPermission] = useState();

  const renderAs =
    question?.config?.renderAs !== undefined
      ? question?.config?.renderAs
      : "url";

  const showFrameByFrameControls =
    question?.config?.showFrameByFrameControls !== undefined
      ? question?.config?.showFrameByFrameControls
      : false;

  const { subjectId } = useParams();

  const mediaObject = useUserMedia(
    renderAs === "value" ? value : null,
    subjectId
  );

  const mediaUrl = useMemo(() => mediaObject.userMediaUri ? mediaObject.userMediaUri : question.videoUrl, [mediaObject.userMediaUri, question.videoUrl]);

  useEffect(() => {
    populateHasAllowSubjectMediaAudioPermission();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateHasAllowSubjectMediaAudioPermission = async () => {
    if (user?.accountType === "subject") {
      setHasAllowSubjectMediaAudioPermission(true);
    }

    const subjectGroups = await SubjectService.getSubjectGroups(subjectId);
    const hasPerm = await PermissionsService.hasPermissionForSubject(subjectGroups, GroupPermission.ALLOW_SUBJECT_MEDIA_AUDIO);
    setHasAllowSubjectMediaAudioPermission(hasPerm);
  };

  const handlePlayingComplete = () => {
    changeValue(true);
  };

  if (question?.config?.showToSide && !isInSideView) {
    return null;
  }

  const getEnhancedVideoPlayer = () => {
    const enhancedVideoPlayerProps = {
      videoData: {
        url: mediaUrl,
      },
      options: {
        isMonitoredVideo: true,
        allowMuteChange: hasAllowSubjectMediaAudioPermission
      },
      onPlayingComplete: handlePlayingComplete,
    };
    return <EnhancedVideoPlayer {...enhancedVideoPlayerProps} />;
  }

  const getPlayerToggle = () => {
    if (user?.profile?.superAdmin !== true) {
      return null;
    }
    return (
      <div style={{ textAlign: 'left' }}>
        <Button
          onClick={() => setUseEnhancedPlayer(!useEnhancedPlayer)}
          size='tiny'
          color='grey'
          content={useEnhancedPlayer ? 'Use Standard Player' : 'Use Experimental Player'}
        />
      </div>
    );
  };

  const getPlayer = () => {
    if (useEnhancedPlayer) {
      return getEnhancedVideoPlayer();
    }

    return (<VideoPlayer
      width={question.width || 320}
      height={question.height || 240}
      controls
      isMonitoredVideo
      onPlayingComplete={handlePlayingComplete}
      mediaUrl={mediaUrl}
      showFrameByFrameControls={showFrameByFrameControls}
      backgroundColor={'transparent'}
      style={{ display: "block", margin: "auto" }}
      hasAllowSubjectMediaAudioPermission={hasAllowSubjectMediaAudioPermission}
    />);

  }
  const shouldRenderPlayer = (mediaObject.userMediaUri && renderAs === "value") || (question.videoUrl && renderAs === "url");

  return (
    <Form.Field>
      {!isReadOnly ? (
        <>
          <Container textAlign="center">
            {mediaObject.isLoading && (
              <Progress percent={mediaObject.downloadProgress} indicating />
            )}
            {mediaObject.isError && (
              <Message negative>{t(mediaObject.errorMsg) + ':' + mediaObject.errorCode}</Message>
            )}
            {(shouldRenderPlayer && !forceUseEnhancedPlayer) && getPlayerToggle()}
            {shouldRenderPlayer && getPlayer()}

            <AttachmentDownload
              subjectId={subjectId}
              reference={value}
            />
          </Container>
        </>
      ) : (
        <Form.Input
          type={"text"}
          name={question.code}
          id={question.code}
          defaultValue={
            value === "true"
              ? i18next.t("MONITORED_VIDEO_WATCHED")
              : i18next.t("MONITORED_VIDEO_NOT_WATCHED")
          }
          disabled={true}
        />
      )}
    </Form.Field>
  );
};

export default withContainer(withLabel(withError(withTranslation()(MonitoredVideo))));
