import React, { useContext, useEffect, useRef, useState } from 'react'; // TODO: Remove useContext with AT-2041
import { typeHelper } from 'atom5-branching-questionnaire';
import { DISPLAY_TIME_FORMAT } from './constants/DISPLAY_TIME_FORMAT';
import ControlPanel from './components/ControlPanel';
import { Transition } from "semantic-ui-react";
import { useFullScreen } from '../../../context/FullScreenContext';
import ReactPlayer from 'react-player';
import ConfigContext from '../../../context/ConfigContext'; // TODO: Remove ConfigContext with AT-2041

const defaultOptions = {
  defaultTimeDisplayUnit: DISPLAY_TIME_FORMAT.MINUTES_SECONDS.value,
  allowMuteChange: true,
  isMonitoredVideo: false
};

const defaultTimeSkipOptions = {
  isEnabled: true,
  sizes: [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 2, 5, 10, 20, 30, 60]
};

const defaultPlaybackRateOptions = {
  isEnabled: true,
  rates: [0.1, 0.2, 0.5, 0.75, 1, 1.25, 1.5, 2, 5]
};

const defaultAnnotationOptions = {
  isEnabled: false, // AT-2043
  categories: [
    { code: 'general', titleDefault: 'General', titleTranslationKey: 'ENHANCED_VIDEO_PLAYER_MARKERS_CATEGORY_TITLE_GENERAL' },
  ]
};

const EnhancedVideoPlayer = ({
  videoData,

  options: passedOptions,
  timeSkipOptions: passedTimeSkipOptions,
  playbackRateOptions: passedPlaybackRateOptions,
  annotationOptions: passedAnnotationOptions,
  annotations: passedAnnotations,

  onReady,
  onError,
  onPlayingComplete,
}) => {
  const fullScreenContext = useFullScreen();

  // ==============================================================================================================
  /* This should be passed in with the 
    timeSkipOptions: {
      sizes: [0.01,3.4,7.8,]
    }
    but a rush for demo means this hack is in place for now.
    Going foward no config loads or specifics within this component or child components.
    Everything to be transformed in whatever uses this, and passed in - in generic form!!!
    **** AT-2041 to revert this hack ****
  */
  const config = useContext(ConfigContext);
  const sizes = config?.ui?.components?.videoPlayer?.skip?.availableStepSizes || [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 2, 5, 10, 20, 30, 60];
  // ==============================================================================================================

  const options = { ...defaultOptions, ...passedOptions };
  const timeSkipOptions = { ...defaultTimeSkipOptions, ...passedTimeSkipOptions, ...{sizes} }; // TODO: Remove {sizes} part of hack commented above with AT-2041
  const playbackRateOptions = { ...defaultPlaybackRateOptions, ...passedPlaybackRateOptions };
  const annotationOptions = { ...defaultAnnotationOptions, ...passedAnnotationOptions };

  const playerRef = useRef();
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    if (isReady === false) {
      return;
    }
    const internalPlayer = getInternalPlayer();
    if (internalPlayer == null) {
      console.error('Error: internalPlayer is NULL');
      return;
    }

    const listeners = [
      { object: internalPlayer, event: 'timeupdate', handler: handleVideoTimeUpdate }
    ];
    if (options?.isMonitoredVideo) {
      listeners.push({ object: internalPlayer, event: 'seeking', handler: handleVideoSeeking });
      listeners.push({ object: internalPlayer, event: 'seeked', handler: handleVideoSeeked });
    }

    for (const listener of Object.values(listeners)) {
      listener.object.addEventListener(listener.event, listener.handler);
    }

    return () => {
      for (const listener of Object.values(listeners)) {
        listener.object.removeEventListener(listener.event, listener.handler);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady]);

  const handleVideoTimeUpdate = () => {
    if (options?.isMonitoredVideo === false) {
      const vp = { ...viewingProgressRef.current };
      vp.currentTimeMilliseconds = sToMs(getCurrentTime());
      setViewingProgress(vp);
      return;
    }

    const vp = { ...viewingProgressRef.current };
    if (vp.currentTimeMilliseconds > vp.latestViewedTimeMilliseconds) {
      vp.latestViewedTimeMilliseconds = vp.currentTimeMilliseconds;
    }

    vp.currentTimeMilliseconds = sToMs(getCurrentTime());
    setViewingProgress(vp);
  };

  const handleVideoSeeking = () => {
    if (options?.isMonitoredVideo === false) {
      return;
    }

    const vp = { ...viewingProgressRef.current };
    if (vp.isManualSeeking === false) {
      vp.isManualSeeking = true;
      vp.seekTo = sToMs(getCurrentTime());
      vp.latestViewedTimeAtManualSeekStart = vp.latestViewedTimeMilliseconds;
      setViewingProgress(vp);
    }
  };

  const handleVideoSeeked = () => {
    if (options?.isMonitoredVideo === false) {
      return;
    }

    const vp = { ...viewingProgressRef.current };
    if (vp.isManualSeeking) {
      if (vp.seekTo > vp.latestViewedTimeAtManualSeekStart) {
        _setCurrentPlayerTime(msToS(vp.latestViewedTimeAtManualSeekStart));
      }
    }

    vp.latestViewedTimeMilliseconds = vp.latestViewedTimeAtManualSeekStart;
    vp.isManualSeeking = false;
    vp.seekTo = null;

    setViewingProgress(vp);
  };

  const [viewingProgress, _setViewingProgress] = useState({
    // Time
    durationMilliseconds: undefined,
    currentTimeMilliseconds: 0,
    latestViewedTimeMilliseconds: 0,
    // Frame
    frameRate: videoData?.frameRate,
    currentFrameIndex: 0,
    // Seeking
    isManualSeeking: false,
    latestViewedTimeAtManualSeekStart: 0,
    seekTo: null
  });
  const viewingProgressRef = useRef(viewingProgress);
  const setViewingProgress = (obj) => {
    viewingProgressRef.current = obj;
    _setViewingProgress(obj);
  };

  const [controlState, _setControlState] = useState({
    timeDisplayUnit: options.defaultTimeDisplayUnit,
    isControlPanelVisible: true,
    isControlPanelLockedOpen: false,
    isPlaying: false,
    isMuted: true,
    playbackRate: 1,
    timeSkipSize: 1,
    isFullScreen: false,
    isExpandedView: false
  });
  const controlStateRef = useRef(controlState);
  const setControlState = (obj) => {
    controlStateRef.current = obj;
    _setControlState(obj);
  };

  const [annotations, setAnnotations] = useState(passedAnnotations != null ? passedAnnotations : []);

  const fullScreenContainerRef = useRef(null);

  // TODO: AUTOHIDE - undone in AT-1910, but will be picked up in AT-2043
  // const autoHideControlPanelTimeoutHandle = useRef();

  // TODO: AUTOHIDE - undone in AT-1910, but will be picked up in AT-2043
  // useEffect(() => {
  //   return () => {
  //     clearTimeout(autoHideControlPanelTimeoutHandle.current);
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    // Frame
    const frameRate = typeHelper.parseNumber(viewingProgress.frameRate);
    if (isNaN(frameRate)) {
      setViewingProgress({ ...viewingProgress, currentFrameIndex: NaN });
      return;
    }
    const currentTimeSeconds = msToS(viewingProgress.currentTimeMilliseconds);
    const frameIndex = currentTimeSeconds * frameRate;
    setViewingProgress({ ...viewingProgress, currentFrameIndex: frameIndex });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewingProgress.currentTimeMilliseconds, viewingProgress.frameRate])

  const handleControlClick = (action, data) => {
    console.log('handleControlClick', action, data);
    switch (action) {
      // Control Panel Lock / Unlock
      case 'controlpanel-lock':
        setControlState({ ...controlState, isControlPanelLockedOpen: true, isControlPanelVisible: true });
        break;
      case 'controlpanel-unlock':
        setControlState({ ...controlState, isControlPanelLockedOpen: false });
        break;

      // Play / Pause
      case 'play':
        setControlState({ ...controlState, isPlaying: true });
        break;
      case 'pause':
        setControlState({ ...controlState, isPlaying: false });
        break;

      // Playback Rate
      case 'playbackrate':
        if (data.action === 'ratechange') {
          setControlState({ ...controlState, playbackRate: data.value });
        }
        break;

      // Time Skip
      case 'timeskip':
        if (data.action === 'sizechange') {
          setControlState({ ...controlState, timeSkipSize: data.value });
        } else if (data.action === 'skip') {
          const desiredTimeMs = viewingProgress.currentTimeMilliseconds + data.value;
          let time = desiredTimeMs;
          if (desiredTimeMs < 0) {
            time = 0;
          } else if (desiredTimeMs > viewingProgress.durationMilliseconds) {
            time = viewingProgress.durationMilliseconds;
          }

          const timeInSeconds = msToS(time);
          _setCurrentPlayerTime(timeInSeconds);
        }
        break;

      // Annotations
      case 'annotations':
        if (data.action === 'update') {
          setAnnotations(data.value);
        }
        break;

      // Time Display Units
      case 'timeFormat':
        if (data.action === 'formatchange') {
          setControlState({ ...controlState, timeDisplayUnit: data.value });
        }
        break;

      // Mute / Unmute
      case 'mute':
        setControlState({ ...controlState, isMuted: true });
        break;
      case 'unmute':
        setControlState({ ...controlState, isMuted: false });
        break;

      // FullScreen
      case 'fullscreen-enter':
        requestFullScreen(true);
        break;
      case 'fullscreen-exit':
        requestFullScreen(false);
        break;

      // Expanded View
      case 'expandedview-enter':
        setControlState({ ...controlState, isExpandedView: true });
        break;
      case 'expandedview-exit':
        setControlState({ ...controlState, isExpandedView: false });
        break;

      default:
        console.error('[EnhancedVideoPlayer][handleControlClick] Unhandled action', action, data);
    }
  };

  useEffect(() => {
    // Handle keyboard shortcut escape within the full screen container, handled in the DOM - this is the only cae where full state may change outside of our control here.
    if (fullScreenContext.isFullScreen === false) {
      requestFullScreen(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fullScreenContext.isFullScreen]);

  const requestFullScreen = (isFullScreenRequested) => {
    try {
      fullScreenContext.setIsFullScreen(
        fullScreenContainerRef,
        isFullScreenRequested
      );
      setControlState({
        ...controlState,
        isFullScreen: isFullScreenRequested,
        isExpandedView: false
      });
    } catch (error) {
      console.error('[EnhancedVideoPlayer][requestFullScreen] Error', error);
      setControlState({ ...controlState, isFullScreen: false });
    }
  };

  // TODO: AUTOHIDE - undone in AT-1910, but will be picked up in AT-2043
  // const handleHideControlPanelTimeout = () => {
  //   console.log('handleHideControlPanelTimeout');
  //   clearTimeout(autoHideControlPanelTimeoutHandle.current);
  //   // controlState is old at this point, as it is the state at the point the setTimeout is created - by design in react
  //   // so we use the ref version here
  //   setControlState({ ...controlStateRef.current, isControlPanelVisible: false });
  // }

  const handlePlayerMouseMove = () => {
    // TODO: AUTOHIDE - undone in AT-1910, but will be picked up in AT-2043
    // ensureControlPanelState();
    // autoHideControlPanelTimeoutHandle.current = setTimeout(handleHideControlPanelTimeout, 2500);
  }

  const handleControlPanelMouseMove = () => {
    // TODO: AUTOHIDE - undone in AT-1910, but will be picked up in AT-2043
    // ensureControlPanelState();
  }

  // TODO: AUTOHIDE - undone in AT-1910, but will be picked up in AT-2043
  // const ensureControlPanelState = () => {
  //   if (controlState.isControlPanelLockedOpen) {
  //     clearTimeout(autoHideControlPanelTimeoutHandle.current);
  //     return;
  //   }
  //   if (!controlState.isControlPanelVisible) {
  //     setControlState({ ...controlState, isControlPanelVisible: true });
  //   }
  //   clearTimeout(autoHideControlPanelTimeoutHandle.current);
  // }

  const containerStyle = {
    ...styles.containerCore,
    ...(controlState.isExpandedView ? styles.containerExpandedView : styles.containerDefault)
  };

  const handleMediaLoadError = (e) => {
    console.error('handleMediaLoadError', e);
    if (onError != null) {
      onError(e);
    }
  };

  const handleMediaReady = async () => {
    setIsReady(true);
    const player = getPlayer();
    if (player == null) {
      console.error('Error: player is NULL');
      return;
    }

    const durationMilliseconds = sToMs(player.getDuration()); // getDuration: Returns the duration (in seconds) of the currently playing media
    setViewingProgress({ ...viewingProgress, durationMilliseconds });

    if (onReady != null) {
      onReady();
    }
  };

  const getPlayer = () => {
    const player = playerRef?.current;
    return player;
  };

  const getInternalPlayer = () => {
    const player = getPlayer();
    if (player != null) {
      return player.getInternalPlayer();
    }
    return null;
  };

  const getCurrentTime = () => {
    const player = getPlayer();
    if (player != null) {
      return player.getCurrentTime();
    }
    return null;
  };

  const _setCurrentPlayerTime = (time) => {
    const player = getPlayer();
    if (player == null) {
      console.error('Error: player is NULL');
      return;
    }

    const isDecimal = time % 1 !== 0;
    const seekType = isDecimal ? 'fragment' : 'seconds';
    player.seekTo(time, seekType);
  };

  const handleMediaEnded = () => {
    setControlState({ ...controlState, isPlaying: false });
    if (onPlayingComplete != null) {
      onPlayingComplete();
    }
  };

  const handleProgressChange = (timeMs) => {
    const timeInSeconds = msToS(timeMs);
    _setCurrentPlayerTime(timeInSeconds);
  };

  const sToMs = (s) => {
    return s * 1000;
  };

  const msToS = (ms) => {
    return ms / 1000;
  }

  return (
    <>
      <div
        ref={fullScreenContainerRef}
        style={containerStyle}
        onMouseMove={handlePlayerMouseMove}
      >
        <div style={styles.playerOuterContainer}>
          <ReactPlayer
            ref={playerRef}
            playing={controlState.isPlaying}
            playbackRate={controlState.playbackRate}
            muted={controlState.isMuted}
            height={controlState.isFullScreen
              ? '100%'
              : controlState.isExpandedView ? '800px' : '500px'
            }
            width={controlState.isExpandedView ? '100%' : null}
            url={videoData?.url}
            controls={false}
            onReady={handleMediaReady}
            onError={handleMediaLoadError}
            onEnded={handleMediaEnded}
            preload={'none'}
            config={{
              file: {
                attributes: {
                  onContextMenu: e => e.preventDefault(),
                  controlsList: 'nodownload',
                }
              }
            }}
          />
        </div>

        <Transition visible={controlState.isControlPanelVisible} animation='fade' duration={500}>
          <div
            style={styles.controlPanelContainer}
            onMouseMove={handleControlPanelMouseMove}
          >
            <ControlPanel
              viewingProgress={viewingProgress}
              controlState={controlState}
              options={options}
              timeSkipOptions={timeSkipOptions}
              playbackRateOptions={playbackRateOptions}
              annotationOptions={annotationOptions}
              annotations={annotations}
              onControlClick={handleControlClick}
              onProgressChange={handleProgressChange}
            />
          </div>
        </Transition>
      </div>
    </>
  );
};
const styles = {
  containerCore: {
    display: 'flex',
    // border: '4px solid #333',
    // border: '4px solid orange', // TODO: Remove / change colour? grey??
    position: 'relative',
  },
  containerDefault: {
    width: '640px',
  },
  containerExpandedView: {
    width: '100%',
  },

  controlPanelContainer: {
    position: 'absolute',
    paddingTop: 20,
    bottom: 0,
    width: '100%',
    zIndex: 100,
    backgroundImage: "linear-gradient(to top, black, rgba(0,0,0,0))",
  },

  playerOuterContainer: {
    backgroundColor: '#222',
    textAlign: 'center',
    width: '100%'
  },
};

export default EnhancedVideoPlayer;
