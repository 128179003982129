import React, { Component } from "react";
import { Icon, Message, Placeholder, Table } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import moment from "moment";
import SubjectService from "../../SubjectService";

import DateRangeControls from "../../components/DateRangeControls";

class SubjectVitalSignsTab extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = this.initialise();
  }

  initialise = () => {
    const subjectId = this.props.match.params.subjectId;
    const startDate = moment().subtract(7, "days");
    const endDate = moment();

    SubjectService.getSubjectVitalSignsData(
      subjectId,
      startDate.format("YYYY-MM-DD"),
      endDate.format("YYYY-MM-DD")
    )
      .then((result) => {
        this.setState({ data: result, startDate, endDate, loading: false });
      })
      .catch(this.errorHandler);

    return {
      data: null,
      startDate: startDate,
      endDate: endDate,
      error: false,
      loading: true,
      flagColorKey: ["grey", "yellow", "orange", "red"],
      newsColorKey: ["none", "grey", "yellow", "orange", "red"],
    };
  };

  handleDateChange = (startDate, endDate) => {
    const subjectId = this.props.match.params.subjectId;
    this.setState({ loading: true });
    SubjectService.getSubjectVitalSignsData(subjectId, startDate, endDate)
      .then((result) => {
        this.setState({ data: result, startDate, endDate, loading: false });
      })
      .catch(this.errorHandler);
  };

  calculateOverall = (scores) => {
    let flag = false;
    let newsColorIndex = 4;
    let total = scores.reduce((acc, curr) => {
      if (curr === 3) {
        flag = true;
      }
      return acc + curr;
    });

    if (total === 0) {
      newsColorIndex = 0;
    } else if (total < 5) {
      if (!flag) {
        newsColorIndex = 1;
      } else {
        newsColorIndex = 2;
      }
    } else if (total < 7) {
      newsColorIndex = 3;
    }

    return {
      newsColorIndex,
      score: total,
    };
  };

  errorHandler = (error) => {
    console.error(error);
    this.setState({ loading: false, error: true });
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.match.params.subjectId !== this.props.match.params.subjectId
    ) {
      this.setState(this.initialise());
    }
  };

  render() {
    const { t } = this.props;
    let headers = [];
    let rows = [];

    if (!this.state.loading && this.state.data) {
      let numberOfDays = this.state.data.metrics[0].data.length;

      headers.push(
        <Table.HeaderCell key={"date"}>{t("GLOBAL_DATE")}</Table.HeaderCell>
      );
      headers.push(
        this.state.data.metrics.map((metric) => {
          return (
            <Table.HeaderCell key={metric.code}>
              {t(metric.label)}
            </Table.HeaderCell>
          );
        })
      );
      headers.push(
        <Table.HeaderCell key={"overall"}>{t("GLOBAL_TOTAL")}</Table.HeaderCell>
      );

      for (let i = 0; i < numberOfDays; i++) {
        let mainRow = [];
        let scoreRow = [];
        let scoreArray = [];
        let overall;
        let date;

        this.state.data.metrics.forEach((metric, index) => {
          let iconString = "flag";
          let color = this.state.flagColorKey[metric.data[i].score];
          if (metric.data[i].value === null) {
            iconString = "close";
            color = "black";
          }

          date = metric.data[i].date;

          if (index === 0) {
            mainRow.push(
              <Table.HeaderCell key={metric.data[i].date} rowSpan="2">
                {metric.data[i].date}
              </Table.HeaderCell>
            );
          }
          mainRow.push(
            <Table.Cell key={`${metric.code}-${metric.data[i].date}-value`}>
              {metric.data[i].value}
            </Table.Cell>
          );
          scoreRow.push(
            <Table.Cell key={`${metric.code}-${metric.data[i].date}-points`}>
              <Icon name={iconString} color={color} />
            </Table.Cell>
          );
          scoreArray.push(metric.data[i].score);
        });

        overall = this.calculateOverall(scoreArray);

        mainRow.push(
          <Table.Cell key={`overall-${date}-score`}>{overall.score}</Table.Cell>
        );
        if (overall.newsColorIndex === 0) {
          scoreRow.push(
            <Table.Cell key={`overall-${date}-flag`}>
              <Icon name="flag outline" />
            </Table.Cell>
          );
        } else {
          scoreRow.push(
            <Table.Cell key={`overall-${date}-flag`}>
              <Icon
                name="flag"
                color={this.state.newsColorKey[overall.newsColorIndex]}
              />
            </Table.Cell>
          );
        }

        rows.push(<Table.Row key={`${date}-dataRow`}>{mainRow}</Table.Row>);
        rows.push(<Table.Row key={`${date}-flagRow`}>{scoreRow}</Table.Row>);
      }
    }

    return (
      <>
        {this.state.loading && (
          <Placeholder>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
            <Placeholder.Paragraph>
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Paragraph>
          </Placeholder>
        )}

        <DateRangeControls
          onChange={this.handleDateChange}
          start={this.state.startDate}
          end={this.state.endDate}
        />

        {/* TODO: This should query the permissions not check for data but which permission it uses is unknown at this time */}
        {!this.state.loading && !this.state.data && (
          <>
            <Message>{t("SUBJECT_VIEW_MISSING_DATA_PERMISSION")}</Message>
          </>
        )}

        {!this.state.loading && (
          <>
            <Message warning>{t("CONSUMER_GRADE_DEVICE_WARNING")}</Message>
            <div style={{ display: "flex", justifyContent: "space-around" }}>
              <div>
                <Icon name="flag" color="grey" /> = 0
              </div>
              <div>
                <Icon name="flag" color="yellow" /> = 1
              </div>
              <div>
                <Icon name="flag" color="orange" /> = 2
              </div>
              <div>
                <Icon name="flag" color="red" /> = 3
              </div>
            </div>
            <Table celled>
              <Table.Header>
                <Table.Row>{headers}</Table.Row>
                {rows}
              </Table.Header>
            </Table>
          </>
        )}
      </>
    );
  }
}

export default withTranslation()(SubjectVitalSignsTab);
