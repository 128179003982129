import React, { useEffect, useState } from "react";
import { Button, Form, Loader, Message } from "semantic-ui-react";
import { withTranslation } from "react-i18next";
import { v4 as uuid_v4 } from "uuid";
import SignatureService from "../../services/SignatureService";
import withError from "./hocs/withError";
import { compose } from "redux";
import { logevent } from "../../services/FirebaseAnalytics";
import withContainer from "./hocs/withContainer";

const PROCESS_STATUS = {
  NO_DATA_CONNECTION: "NO_DATA_CONNECTION",
  INIT: "INIT",
  THIRD_PARTY: "THIRD_PARTY",
  RETURNED: "RETURNED",
  SUCCESS: "SUCCESS",
};

const SIGNATURE_STATUS = {
  OK: "OK",
  CANCEL: "CANCEL",
  DECLINE: "DECLINE",
  ERROR: "ERROR",
};

const Signature = ({
  question,
  value,
  changeValue,
  t,
  subjectId,
  questionnaireId,
  getAnswerMapValue,
}) => {
  const [processStatus, setProcessStatus] = useState(
    value ? PROCESS_STATUS.SUCCESS : PROCESS_STATUS.INIT
  );
  const [returnStatus, setReturnStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const [signatureData, setSignatureData] = useState(null);
  const [internalError, setInternalError] = useState(null);

  const [windowHandle, setWindowHandle] = useState(null);
  const [callbackTimerHandle, setCallbackTimerHandle] = useState(null);

  const startButtonTranslationKey =
    question && question.config.startButtonTranslationKey
      ? question.config.startButtonTranslationKey.toUpperCase()
      : "DIGITALSIGNATURE_START";
  const returnedFromThirdPartyTranslationKey =
    question && question.config.returnedFromThirdPartyTranslationKey
      ? question.config.returnedFromThirdPartyTranslationKey.toUpperCase()
      : "DIGITALSIGNATURE_RETURNED";
  const successTranslationKey =
    question && question.config.successTranslationKey
      ? question.config.successTranslationKey.toUpperCase()
      : "DIGITALSIGNATURE_SUCCESS";

  const inputQuestionValues = question.config?.inputQuestionValues;

  const handleInitClick = async () => {
    // Open the window early, if any later or any delays, then browser will probably block it.
    // Doing it now is treated as 'user invoking it', but if it's delayed awaiting the service call, browsers assume it's not user action.
    // For this reason we create the window now, grab the handle to it, and change the location when we have it from DocuSign
    let wh = window.open("/thirdparty/digitalsignatureredirect", uuid_v4(), "");
    setLoading(true);
    resetState();
    logevent('docusign_action',{status:'activated'});
    try {
      const nameValue =
        inputQuestionValues === undefined
          ? undefined
          : getAnswerMapValue(inputQuestionValues?.name);
      const emailAddressValue =
        inputQuestionValues === undefined
          ? undefined
          : getAnswerMapValue(inputQuestionValues?.emailaddress);

      const response = await SignatureService.createSignatureRequest(
        questionnaireId,
        question.id,
        subjectId,
        nameValue,
        emailAddressValue
      );

      if (response && response.redirectUrl) {
        const currentSignatureData = { ...signatureData };
        currentSignatureData.redirectUrl = response.redirectUrl;
        currentSignatureData.envelopeId = response.envelopeId;
        currentSignatureData.returnUrl = response.returnUrl;

        setLoading(false);
        setSignatureData(currentSignatureData);
        setProcessStatus(PROCESS_STATUS.THIRD_PARTY);

        wh.location.href = response.redirectUrl;
        setWindowHandle(wh);

        if (!wh) {
          setProcessStatus(PROCESS_STATUS.INIT);
          setInternalError(t("DIGITALSIGNATURE_ERROR"));
        }
      }
    } catch (e) {
      processSignatureStatus(PROCESS_STATUS.ERROR);
    }
  };

  useEffect(() => {
    if (windowHandle) {
      setCallbackTimerHandle(
        setInterval(() => {
          let windowUrl = null;
          try {
            windowUrl = windowHandle.location?.href;
          } catch (e) {
            // Catches when calls to the location are blocked by cross origin checks. We can ignore these, we only need to know when it comes back to us.
            windowUrl = null;
          }
          handleCallbackTimerTick(windowUrl);
        }, 500)
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowHandle]);

  const resetState = () => {
    setSignatureData({});
    setReturnStatus(null);
    resetValue();
  };

  const resetValue = () => {
    if (value !== null) {
      changeValue(null);
      setInternalError(null);
    }
  };

  const tidyNewWindow = () => {
    if (windowHandle) {
      windowHandle.close();
    }
    setWindowHandle(null);

    if (callbackTimerHandle) {
      clearInterval(callbackTimerHandle);
    }
    setCallbackTimerHandle(null);
  };

  useEffect(() => {
    return () => {
      //tidyNewWindow();
    };
  });

  const handleCallbackTimerTick = (windowUrl) => {
    if (windowUrl && isUrlMatch(windowUrl)) {
      setTimeout(() => {
        tidyNewWindow();
      }, 1000);

      setProcessStatus(PROCESS_STATUS.RETURNED);
      const parsedUrl = new URL(windowUrl);
      // This is the raw status returned on the querystring
      const thirdPartyStatus = parsedUrl.searchParams.get("event");
      const status = getSigningStatusFromThirdPartyValue(thirdPartyStatus);

      const returnStatus = {
        status: status,
        thirdPartyStatus: thirdPartyStatus,
      };
      setReturnStatus(returnStatus);
    }
  };

  useEffect(() => {
    if (returnStatus !== null) {
      if (returnStatus.status === SIGNATURE_STATUS.OK) {
        // Url can be maniuplated easily, so we'll go and check the status behind the scenes.
        processSignatureResponse();
      } else {
        processSignatureStatus(returnStatus.status);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnStatus]);

  const isUrlMatch = (windowUrl) => {
    if (!windowUrl || windowUrl.includes("/digitalsignatureredirect")) {
      return false;
    }
    if (!signatureData.returnUrl) {
      return false;
    }
    return windowUrl.includes(signatureData.returnUrl);
  };

  const getSigningStatusFromThirdPartyValue = (thirdPartyStatus) => {
    thirdPartyStatus = thirdPartyStatus.trim().toLowerCase();
    let status;
    switch (thirdPartyStatus) {
      case "signing_complete":
        status = SIGNATURE_STATUS.OK;
        break;
      case "cancel":
        status = SIGNATURE_STATUS.CANCEL;
        break;
      case "decline":
        status = SIGNATURE_STATUS.DECLINE;
        break;
      default:
        status = SIGNATURE_STATUS.ERROR;
    }
    return status;
  };

  const processSignatureResponse = async () => {
    const currentSignatureData = signatureData;
    currentSignatureData.redirectUrl = null;

    setLoading(true);

    const response = await SignatureService.processSignatureResponse(
      returnStatus.thirdPartyStatus,
      signatureData.envelopeId,
      subjectId
    );

    setLoading(false);

    if (response !== null) {
      currentSignatureData.signingStatus = response.signingStatus;
      if (currentSignatureData.signingStatus === SIGNATURE_STATUS.OK) {
        currentSignatureData.attachmentData =
          "data:image/png;base64," + response.attachmentData;
        currentSignatureData.attachmentReference = response.attachmentReference;

        setProcessStatus(PROCESS_STATUS.SUCCESS);
        setInternalError(null);
        logevent('docusign_action',{status:'success'});
        changeValue(currentSignatureData.attachmentReference);
      }
    } else {
      processSignatureStatus(currentSignatureData.status);
    }
    setSignatureData(currentSignatureData);
  };

  const processSignatureStatus = (status) => {
    // SIGNATURE_STATUS.OK should never get here, only other statuses.
    if (status === SIGNATURE_STATUS.CANCEL) {
      setProcessStatus(PROCESS_STATUS.INIT);
      logevent('docusign_action',{status:'canceled'});
      setInternalError(t("DIGITALSIGNATURE_ERROR_CANCEL"));
      resetValue();
    } else if (status === SIGNATURE_STATUS.DECLINE) {
      setProcessStatus(PROCESS_STATUS.INIT);
      logevent('docusign_action',{status:'declined'});
      setInternalError(t("DIGITALSIGNATURE_ERROR_DECLINE"));
      resetValue();
    } else {
      setProcessStatus(PROCESS_STATUS.INIT);
      logevent('docusign_action',{status:'error'});
      setInternalError(t("DIGITALSIGNATURE_ERROR"));
      resetValue();
    }
  };

  return (
    <>
      {processStatus === PROCESS_STATUS.INIT && (
        <Form.Group inline>
          <Button
            type="button"
            primary
            size="large"
            onClick={handleInitClick}
            disabled={loading}
            loading={loading}
          >
            {t(startButtonTranslationKey)}
          </Button>
        </Form.Group>
      )}

      <Loader
        active={processStatus === PROCESS_STATUS.THIRD_PARTY}
        inline={true}
        size={"large"}
      />

      {processStatus === PROCESS_STATUS.RETURNED && (
        <Message positive>{t(returnedFromThirdPartyTranslationKey)}</Message>
      )}

      {processStatus === PROCESS_STATUS.SUCCESS && (
        <Message positive>{t(successTranslationKey)}</Message>
      )}

      <Message error visible={internalError !== null}>
        {internalError}
      </Message>
    </>
  );
};

const enhance = compose(withContainer, withTranslation(), withError);

export default enhance(Signature);
