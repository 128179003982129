import React, { memo } from 'react';
import ControlButton from './ControlButton';
import { withTranslation } from 'react-i18next';
import { typeHelper } from 'atom5-branching-questionnaire';
import PlaybackRateControls from './PlaybackRateControls';
import TimeSkipControls from './TimeSkipControls';
import TimeDisplay from './TimeDisplay';
import AnnotationControls from './AnnotationControls';
import { useFullScreen } from '../../../../context/FullScreenContext';
import TimeFormatControls from './TimeFormatControls';

const Controls = ({
  t,
  viewingProgress,
  controlState,
  onControlClick,

  options,
  timeSkipOptions,
  playbackRateOptions,

  annotationOptions,
  annotations
}) => {
  const fullScreenContext = useFullScreen();

  const handleControlClick = (action, data) => {
    onControlClick(action, data);
  }

  const handlePlaybackRateChange = (rate) => {
    onControlClick('playbackrate', { action: 'ratechange', value: rate });
  };

  const handleTimeSkipSizeChange = (size) => {
    onControlClick('timeskip', { action: 'sizechange', value: size });
  }

  const handleTimeSkip = (val) => {
    const valueAsMilliseconds = val * 1000;
    onControlClick('timeskip', { action: 'skip', value: valueAsMilliseconds });
  };

  const handleUpdateAnnotations = (annotations) => {
    onControlClick('annotations', { action: 'update', value: annotations });
  };

  const handleTimeFormatChange = (timeFormat) => {
    onControlClick('timeFormat', { action: 'formatchange', value: timeFormat });
  };

  const showMuteControls = typeHelper.parseBool(options.allowMuteChange);
  const showFullScreenControls = typeHelper.parseBool(fullScreenContext.isFullScreenSupported);
  const showExpandedViewControls = !controlState.isFullScreen;
  const showTimeSkipControls = typeHelper.parseBool(timeSkipOptions?.isEnabled);
  const showAnnotationControls = typeHelper.parseBool(annotationOptions?.isEnabled);
  const showPlaybackRateControls = typeHelper.parseBool(playbackRateOptions?.isEnabled);

  const styles = {
    container: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
    },
    left: {
      paddingLeft: 10,
      flex: 1,
      display: 'flex',
      alignItems: 'center'
    },
    center: {
      justifyContent: 'center',
      alignItems: 'center'
    },
    right: {
      paddingRight: 10,
      flex: 1,
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center'
    }
  };

  return (
    <>
      <div style={styles.container}>

        <div style={styles.left}>
          <ControlButton
            buttonText={controlState.isPlaying
              ? t('ENHANCED_VIDEO_PLAYER_CONTROLS_BUTTON_PAUSE', 'Pause')
              : t('ENHANCED_VIDEO_PLAYER_CONTROLS_BUTTON_PLAY', 'Play')}
            iconName={controlState.isPlaying ? 'pause' : 'play'}
            action={controlState.isPlaying ? 'pause' : 'play'}
            onClick={handleControlClick}
          />

          {showPlaybackRateControls && (
            <PlaybackRateControls
              controlState={controlState}
              playbackRateOptions={playbackRateOptions}
              onChange={handlePlaybackRateChange}
            />
          )}

          <span style={{ marginLeft: 10 }}>
            <TimeDisplay
              viewingProgress={viewingProgress}
              controlState={controlState}
              options={options}
            />
          </span>
        </div>

        <div style={styles.center}>
          {showTimeSkipControls && (
            <TimeSkipControls
              controlState={controlState}
              timeSkipOptions={timeSkipOptions}
              onSizeChange={handleTimeSkipSizeChange}
              onSkip={handleTimeSkip}
            />
          )}

          {showAnnotationControls && (
            <AnnotationControls
              viewingProgress={viewingProgress}
              controlState={controlState}
              annotationOptions={annotationOptions}
              annotations={annotations}
              onUpdateAnnotations={handleUpdateAnnotations}
            />
          )}

        </div>

        <div style={styles.right}>
          <TimeFormatControls
            controlState={controlState}
            onChange={handleTimeFormatChange}
          />

          {showMuteControls && (
            <ControlButton
              buttonText={controlState.isMuted
                ? t('ENHANCED_VIDEO_PLAYER_CONTROLS_BUTTON_UNMUTE', 'Unmute')
                : t('ENHANCED_VIDEO_PLAYER_CONTROLS_BUTTON_MUTE', 'Mute')}
              iconName={controlState.isMuted ? 'volume-off' : 'volume-up'}
              action={controlState.isMuted ? 'unmute' : 'mute'}
              onClick={handleControlClick}
            />
          )}
          {showFullScreenControls && (
            <ControlButton
              buttonText={controlState.isFullScreen
                ? t('ENHANCED_VIDEO_PLAYER_CONTROLS_BUTTON_FULLSCREEN_EXIT', 'Exit full screen')
                : t('ENHANCED_VIDEO_PLAYER_CONTROLS_BUTTON_FULLSCREEN_ENTER', 'Enter full screen')}
              iconName={controlState.isFullScreen ? 'compress-alt' : 'expand-alt'}
              action={controlState.isFullScreen ? 'fullscreen-exit' : 'fullscreen-enter'}
              onClick={handleControlClick}
            />
          )}
          {showExpandedViewControls && (
            <ControlButton
              buttonText={controlState.isExpandedView
                ? t('ENHANCED_VIDEO_PLAYER_CONTROLS_BUTTON_EXPANDEDVIEW_EXIT', 'Exit expanded view')
                : t('ENHANCED_VIDEO_PLAYER_CONTROLS_BUTTON_EXPANDEDVIEW_ENTER', 'Expanded view')}
              iconName={controlState.isExpandedView ? 'compress-alt' : 'expand-alt'}
              iconRotationAngle={45}
              action={controlState.isExpandedView ? 'expandedview-exit' : 'expandedview-enter'}
              onClick={handleControlClick}
            />
          )}
        </div>

      </div>
    </>
  );
};

export default memo(withTranslation()(Controls));
