import React, { useState, useMemo, useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Card } from "semantic-ui-react";
import StaffService from "../../StaffService";
import TrialService from "../../TrialService";
import Page from "../../components/page/Page";
import AuthService from "../../services/AuthService";
import GroupPermission from "../../GroupPermission";


// Update in GroupPermission page when adding to list
const PERMISSION_DEFAULT_TRANSLATION = {
  [GroupPermission.VIEW_SUBJECT]: "View Subject",
  [GroupPermission.CREATE_STAFF]: "Create Staff",
  [GroupPermission.VIEW_SUBJECT_MEDIA]: "View Subject Media",
  [GroupPermission.VIEW_SUBJECT_DATA]: "View Subject Data",
  [GroupPermission.UPDATE_SUBJECT_EMAIL]: "Update Subject Email",
  [GroupPermission.UPDATE_SUBJECT_LANGUAGE]: "Update Subject Language",
  [GroupPermission.UPDATE_SUBJECT_CODE]: "Update Subject Code",
  [GroupPermission.UPDATE_SUBJECT_RECORDS]: "Update Subject Records",
  [GroupPermission.UPDATE_SUBJECT_ACTIVE]: "Update Subject Active",
  [GroupPermission.RESET_SUBJECT_PASSWORD]: "Reset Subject Password",
  [GroupPermission.MANAGE_SUBJECT_ALLERGIES]: "Manage Subject Allergies",
  [GroupPermission.MANAGE_SUBJECT_DEVICES]: "Manage Subject Devices",
  [GroupPermission.MANAGE_SUBJECT_MEDICATION]: "Manage Subject Medication",
  [GroupPermission.MANAGE_SUBJECT_SCHEDULED_ITEMS]: "Manage Subject Scheduled items",
  [GroupPermission.CREATE_SUBJECT]: "Create Subject",
  [GroupPermission.MODIFY_ACTIVATION_WINDOW]: "Modify Activation Window",
  [GroupPermission.MANUAL_QUESTIONNAIRE_DELIVERY]: "Manual Questionnaire Delivery",
  [GroupPermission.CREATE_SUBJECT_QUESTIONNAIRES]: "Create Subject Questionnaires",
  [GroupPermission.SUBMIT_SUBJECT_QUESTIONNAIRES]: "Submit Subject Questionnaires",
  [GroupPermission.MANAGE_SUBJECT_GROUPS]: "Manage Subject Groups",
  [GroupPermission.RECEIVE_ALERTS]: "Receive Alerts",
  [GroupPermission.MANAGE_EXPORTS]: "Manage Exports",
  [GroupPermission.MANAGE_EXPORTS]: "Manage Exports",
  [GroupPermission.CREATE_STAFF]: "Create Staff",
  [GroupPermission.CREATE_STAFF_CREATORS]: "Create Staff Creators",
  [GroupPermission.VIEW_DATA_QUERIES]: "View Data Queries",
  [GroupPermission.RAISE_DATA_QUERIES]: "Raise Data Queries",
  [GroupPermission.ANSWER_DATA_QUERIES]: "Answer Data Queries",
  [GroupPermission.CLOSE_DATA_QUERIES]: "Close Data Queries",
  [GroupPermission.MANAGE_GROUPS]: "Manage Groups",
  [GroupPermission.UPDATE_GROUP_METADATA]: "Update Group metadata",
  [GroupPermission.RANDOMISE_SUBJECT]: "Randomise Subject",
  [GroupPermission.SEARCH_ATTACHMENTS]: "Search Attachments",
  [GroupPermission.VIEW_NOTIFICATIONS]: "View Notifications",
  [GroupPermission.VIEW_SITE_DASHBOARD_REPORTS]: "View Site Dashboard Reports",
  [GroupPermission.UPDATE_ATTACHMENT_WORKFLOW]: "Update Attachment Workflow",
  [GroupPermission.SEARCH_AUDIT]: "Search Audit",
  [GroupPermission.MANAGE_SCREENSHOTS]: "Manage Screenshots",
  [GroupPermission.VIEW_BLURRED_MEDIA]: "View Blurred Media",
  UPDATE_SUBJECT: "Update Subject",
  [GroupPermission.EXPORT_QUESTIONNAIRE_PDF]: "Export Questionnaire to PDF",
  [GroupPermission.BATCH_ATTACHMENTS_VIEW]: "Batch Attachment Upload - View",
  [GroupPermission.BATCH_ATTACHMENTS_UPLOAD_FILES]: "Batch Attachment Upload - Upload Files",
  [GroupPermission.BATCH_ATTACHMENTS_PROCESS_DATA]: "Batch Attachment Upload - Process Data",
  [GroupPermission.BATCH_ATTACHMENTS_DELETE_FILES]: "Batch Attachment Upload - Delete Files",
  [GroupPermission.DOWNLOAD_SUBJECT_MEDIA]: "Can download patient media",
  [GroupPermission.ALLOW_SUBJECT_MEDIA_AUDIO]: "Can play audio for patient media"
};

const RolesPermissionsPage = (props) => {
  const { t } = props;

  const [groupLabels, setGroupLabels] = useState();
  const [roleLabels, setRoleLabels] = useState([]);

  const [myProfile, setMyProfile] = useState()


  const initialise = async () => {
    try {
      const profile = await AuthService.getMyProfile();
      setMyProfile(profile)
      populateLabels();
    } finally {

    }
  };

  useEffect(() => {
    initialise();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const populateLabels = () => {
    TrialService.getGroups().then((allGroups) => {
      const newGroupLabels = {};
      for (const group of allGroups) {
        newGroupLabels[group.code] = group.label;
        addRoleLabelsForGroup(group.code);
      }
      setGroupLabels(newGroupLabels);
    });
  };

  const addRoleLabelsForGroup = () => {
    const newRoleLabels = { ...roleLabels };
    StaffService.getMyRoleTranslations().then((availableRoles) => {
      for (const role of availableRoles) {
        newRoleLabels[role.code] = role.label;
      }
      if (Object.keys(newRoleLabels).length !== 0) {
        setRoleLabels(newRoleLabels);
      }
    });
  };

  const hasLoaded = useMemo(() => {
    if (myProfile == null) return false
    if (groupLabels == null) return false
    if (roleLabels == null) return false

    return true
  }, [myProfile, groupLabels, roleLabels]);



  return (
    <Page
      name="ROLES_PERMISSIONS"
      header={t("ROLES_PERMISSIONS_HEADER", 'Roles & Permissions')}
      subheader={t("ROLES_PERMISSIONS_SUBHEADER", 'View your current roles & permissions')}
      loading={!hasLoaded}
    >

      {hasLoaded && myProfile.groupMappings.map(gM => {
        return (
          <Card fluid style={{ padding: '1rem' }}>
            <Card.Header style={{
              fontSize: '1.7rem',
              paddingBottom: '1rem'
            }}>
              {groupLabels[gM.group.code]}
            </Card.Header>
            {gM.roles.map(r => {
              const per = r.permissions.map(p =>
                PERMISSION_DEFAULT_TRANSLATION[p]
              )
              return (
                <div>
                  <Card.Content description={roleLabels[r.code]} />
                  <Card.Content style={{ paddingBottom: '1rem' }} meta={per.join(', ')} />
                </div>
              )
            })}
          </Card>
        )
      })}
    </Page>
  );
};

export default withTranslation()(RolesPermissionsPage);
