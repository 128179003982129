import RequestHelper, { CONTENT_TYPE } from "../RequestHelper";

export default class ExportService {
  static triggerFullExport() {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + "/export-v2/trigger/full",
      {},
      "POST"
    );
  }

  static triggerRestrictedExport(requestBody) {
    return RequestHelper.send(
        process.env.REACT_APP_SERVER_ADDRESS + "/export-v2/trigger/restricted",
        {
          Accept: CONTENT_TYPE.APPLICATION_OCTETSTREAM,
        },
        "POST",
        null,
        requestBody
    );
  }

  static listExports() {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS + "/export-v2/trigger/",
      {},
      "GET"
    );
  }

  static downloadExport(identifier) {
    return RequestHelper.send(
      process.env.REACT_APP_SERVER_ADDRESS +
        "/export-v2/trigger/download/" +
        encodeURIComponent(identifier),
      {
        Accept: CONTENT_TYPE.APPLICATION_OCTETSTREAM,
      },
      "GET"
    );
  }
}
