import {withTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Button, Card, Grid, GridColumn, GridRow, Icon, Message} from "semantic-ui-react";
import Page from "../../components/page/Page";
import { saveAs } from 'file-saver';

import UploadColumnOverrideModal from "../../components/admin/UploadColumnOverrideModal";
import RequestHelper, {CONTENT_TYPE} from "../../RequestHelper";
import InternationalisationService from "../../InternationalisationService";
import './AdminCardFade.css'

const columnOverrideApiUrl = process.env.REACT_APP_SERVER_ADDRESS + '/exportv2/columnoverride';

const AdminColumnOverridesPage = (props) => {

    const { t } = props;
    const [importColumnOverridesModalVisible, setImportColumnOverridesModalVisible] = useState(false);

    const [info, setInfo] = useState("");
    const [showCard, setShowCard] = useState(false);
    const [cardHeader, setCardHeader] = useState("");
    const [cardMeta, setCardMeta] = useState("");
    const [cardDescription, setCardDescription] = useState("");
    const [uploadReport, setUploadReport] = useState("");
    const [uploadReportText, setUploadReportText] = useState("");

    const cardDetails = {
        "downloadColumnOverride": {
            "header": t("ADMIN_DOWNLOAD_COLUMN_OVERRIDE", "Download Column Override XLS"),
            "meta": t("ADMIN_DOWNLOAD_COLUMN_OVERRIDE_META", "Use this spreadsheet as a starting point for overriding the export column headers"),
            "description": t("ADMIN_DOWNLOAD_COLUMN_OVERRIDE_DESC",
                "Using the generated spreadsheet update the 'Required Variable Label' column to override the existing column title." +
                "<br/><br/>Question Code - this is the internal question definition code" +
                "<br/>Current Export Label - the current export column text" +
                "<br/>Override Variable Label - the new column header to use for the system export" +
                "<br/>Hide - hide this column from the export? Enter 'Y' to hide column")
        },
        "importColumnOverride": {
            "header": t("ADMIN_IMPORT_COLUMN_OVERRIDE", "Upload Column Override XLS"),
            "meta": t("ADMIN_IMPORT_COLUMN_OVERRIDE_META", "Use this to upload a column override spreadsheet"),
            "description": t("ADMIN_IMPORT_COLUMN_OVERRIDE_DESC", "The spreadsheet must be an XLS file, the 4 column headers are:" +
                "<br/><br/>Question Code - this is the internal question definition code" +
                "<br/>Current Export Label - the current export column text" +
                "<br/>Override Variable Label - the new column header to use for the system export" +
                "<br/>Hide - hide this column from the export? Enter 'Y' to hide column")
        },
        "downloadDataDictionary": {
            "header": t("ADMIN_DOWNLOAD_DATA_DICTIONARY", "Download Data Dictionary XLS"),
            "meta":  t("ADMIN_DOWNLOAD_DATA_DICTIONARY_META", "This will trigger a full export so may take a short while"),
            "description": t("ADMIN_DOWNLOAD_DATA_DICTIONARY_DESC", "To generate the data dictionary a full system export is required. " +
                "This export can take some time." +
                "<br/><br/>The generated data dictionary will be downloaded to your browser when it is ready!")
        }
    };

    useEffect(() => {
        cardHeader === "" ? setShowCard(false) : setShowCard(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cardHeader]);

    useEffect(() => {
        if (uploadReport) {
            let outputPass = "";
            let outputFail = "";

            if (uploadReport.exception) {
                setUploadReportText(uploadReport.exception);
            } else if (uploadReport.sheets) {
                uploadReport.sheets.map((sheet) => {
                    const sheetName = sheet?.sheet;
                    if (sheet?.messages?.pass?.length > 0) {
                        outputPass += "<h3>Sheetname: " + sheetName + " : Passes:</h3>";
                        outputPass += sheet.messages.pass
                            .map((p) => "<span style='color:green'> ---" + p.status + "</span><br/>")
                            .join('');
                    }

                    if (sheet?.messages?.fail?.length > 0) {
                        outputFail += "<h3>Sheetname: " + sheetName + " : No updates:</h3>";
                        outputFail += sheet.messages.fail
                            .map((e) => {
                                if (e.status.includes("Question definition empty")) {
                                    return "<span style='color:red'> ---" + e.status + "</span><br/>";
                                } else {
                                    return "<span style='color:orange'> ---" + e.status + "</span><br/>";
                                }
                            })
                            .join('');
                    }
                    return null;
                });

                if (outputFail.length === 0 && outputPass.length === 0) {
                    outputPass = t("ADMIN_COLUMN_OVERRIDE_NOTHING_TO_DO", "Nothing processed, is the spreadsheet in the correct format?");
                }
                setUploadReportText(outputPass + outputFail);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [uploadReport]);

    const processColumnOverrideXls = async (file) => {
        const formData = new FormData();
        formData.append("file", file);
        const headers = {
            "Content-Type": CONTENT_TYPE.MULTIPART_FORMDATA
        };

        setInfo("Uploading spreadsheet....")
        setTimeout(() => {
            setInfo("");
        }, 3000);

        const result = await RequestHelper.send(
            `${columnOverrideApiUrl}/data/upload`,
            headers,
            'POST',
            null,
            formData
        );
        setUploadReport(result);
    }

    const downloadQuestionnaireSpreadsheet = async () => {
        setInfo(t("ADMIN_COLUMN_OVERRIDE_DOWNLOAD", "Generating spreadsheet, please wait...."));
        setTimeout(() => {
            setInfo("");
        }, 5000);

        return await RequestHelper.send(
            `${columnOverrideApiUrl}/data/downloadQuestionnaireDefinitions`,
            { 'Accept-Language': InternationalisationService.getLanguage(), Accept: CONTENT_TYPE.APPLICATION_OCTETSTREAM },
            'GET',
            null
        ).then((blob) => {
            saveAs(blob, "questionnaireDefinition-columnOverride.xlsx");
        });
    };

    const downloadDataDictionary = async () => {
        setInfo(t("ADMIN_COLUMN_OVERRIDE_DD_DOWNLOAD", "Generating data dictionary, your file will download once complete...."));
        setTimeout(() => {
            setInfo("");
        }, 5000);
        return await RequestHelper.send(
            `${columnOverrideApiUrl}/data/downloadDataDictionary`,
            { 'Accept-Language': InternationalisationService.getLanguage(), Accept: CONTENT_TYPE.APPLICATION_OCTETSTREAM },
            'GET',
            null
        ).then((blob) => {
            saveAs(blob, "questionnaireDefinition-dataDictionary.xls");
        });
    };

    const displayCard = (card) => {
        setCardHeader(card.header);
        setCardMeta(card.meta);
        setCardDescription(card.description);
    };

    const clearCardDetails = () => {
        setCardHeader("");
        setCardMeta("");
        setCardDescription("");
    };

    return (
        <Page
            name="Admin Column Override Utility"
            header={t("ADMIN_COLUMN_OVERRIDE_HEADER", "Column Override")}
        >
            <h3>{t("ADMIN_COLUMN_OVERRIDE_WELCOME","Select an option below")}</h3>
            <Grid>
                <GridRow>
                    <GridColumn style={{ width: "400px", maxWidth: "400px" }} width={4}>
                        <Button
                            style={{ marginBottom: 10, width: "300px" }}
                            type="submit"
                            icon
                            color="green"
                            labelPosition="left"
                            onClick={downloadQuestionnaireSpreadsheet}
                            onMouseEnter={() => displayCard(cardDetails.downloadColumnOverride)}
                            onMouseLeave={clearCardDetails}
                        >
                            {t("ADMIN_DOWNLOAD_COLUMN_OVERRIDE", "Download Column Override XLS")} <Icon name="file" />
                        </Button>
                        <Button
                            style={{ marginBottom: 10, width: "300px" }}
                            type="submit"
                            primary
                            icon
                            labelPosition="left"
                            onClick={() => setImportColumnOverridesModalVisible(true)}
                            onMouseEnter={() => displayCard(cardDetails.importColumnOverride)}
                            onMouseLeave={clearCardDetails}
                        >
                            {t("ADMIN_UPLOAD_COLUMN_OVERRIDE", "Upload Column Override XLS")} <Icon name="upload" />
                        </Button>
                        <Button
                            style={{ marginBottom: 10, width: "300px" }}
                            type="submit"
                            primary
                            icon
                            labelPosition="left"
                            onClick={downloadDataDictionary}
                            onMouseEnter={() => displayCard(cardDetails.downloadDataDictionary)}
                            onMouseLeave={clearCardDetails}
                        >
                            {t("ADMIN_DOWNLOAD_DATA_DICTIONARY", "Download data dictionary XLS")} <Icon name="download" />
                        </Button>
                    </GridColumn>
                    <GridColumn width={8}>
                        <div className={showCard ? 'card' : 'card hide'}>
                        <Card style={{ width: "700px", minHeight: "150px" }}>
                            <Card.Content>
                                <Card.Header>{cardHeader}</Card.Header>
                                <Card.Meta>{cardMeta}</Card.Meta>
                                <Card.Description><p dangerouslySetInnerHTML={{ __html: cardDescription }}/></Card.Description>
                            </Card.Content>
                        </Card>
                        </div>
                    </GridColumn>
                </GridRow>
            </Grid>

            {info && info.length>0  &&
                <Message
                    info
                    header={t("GLOBAL_INFO_TITLE", "Info")}
                    content={info}
                />
            }

            {uploadReportText !== "" && (
                <Card style={{ padding: 20, minWidth:"1060px" }} >
                    <p dangerouslySetInnerHTML={{ __html: uploadReportText }}/>
                </Card>
            )}

            <UploadColumnOverrideModal
                open={importColumnOverridesModalVisible}
                onClose={() => {
                    setImportColumnOverridesModalVisible(false)}
                }
                processColumnOverrideXlsHandler={processColumnOverrideXls}
            />
        </Page>
    )
}

export default withTranslation()(AdminColumnOverridesPage);
