import React, {useState} from "react";
import {Grid, GridColumn, GridRow, Modal} from "semantic-ui-react";
import {withTranslation} from "react-i18next";
import DateTimeService from "../../services/DateTimeService";
import TriggerInfoDisplay from "./TriggerInfoDisplay";
import {DateTime} from "luxon";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import workflowHelper from "../../helpers/questionnaireWorkflowHelper";

const QuestionnaireInfoModalButton  = ({questionnaire, definition, t}) => {
  const [isOpen, setIsOpen] = useState(false);

  return <>
    <FontAwesomeIcon
        color={'orange'}
        className='mouse-pointer'
        onClick={(e) => {
          e.stopPropagation()
          setIsOpen(true)
        }}
        size={'lg'}
        icon={['fas', 'info-circle']}
    />
    <Modal
        open={isOpen}
        onClose={() => setIsOpen(false)}
        onOpen={() => setIsOpen(true)}
    >
      <Modal.Header>{t("QUESTIONNAIRE_INFORMATION_TITLE", "Questionnaire ")}</Modal.Header>
      <Modal.Content image scrolling style={{display: 'flex', flexDirection: 'column'}}>
        <h3>{t("Definition")}</h3>
        <Grid>
          <GridRow >
            <GridColumn width={3} ><p style={{paddingRight: '2rem'}}><strong>{t("SUBJECT_TAB_DATA_INFO_DEFINITION_CODE", "Code")}</strong></p></GridColumn>
            <GridColumn width={13} ><p>{definition.code}</p></GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={3} ><p style={{paddingRight: '2rem'}}><strong>{t("SUBJECT_TAB_DATA_INFO_DEFINITION_WORKFLOW", "Workflow")}</strong></p></GridColumn>
            <GridColumn width={13} ><p>{definition.questionnaireWorkflowDefinition?.code}</p></GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={3} ><p style={{paddingRight: '2rem'}}><strong>{t("SUBJECT_TAB_DATA_INFO_DEFINITION_WORKFLOW_TASKS", "Tasks")}</strong></p></GridColumn>
            <GridColumn width={13} ><p>{definition.questionnaireWorkflowDefinition?.questionnaireWorkflowTasks?.map(qWT => qWT.code).toString().replaceAll(',', ', ')}</p></GridColumn>
          </GridRow>
        </Grid>
        <h3 style={{paddingTop: '2rem'}}>{t("Questionnaire")}</h3>
        <Grid>
          <GridRow compact >
            <GridColumn width={3} ><p style={{paddingRight: '2rem'}}><strong>{t("SUBJECT_TAB_DATA_INFO_DEFINITION_CODE", "Created")}</strong></p></GridColumn>
            <GridColumn width={13} ><span>{DateTimeService.build.asDisplayDateTime(
                questionnaire.createdDate
            )}</span> </GridColumn>
          </GridRow>
          <GridRow compact >
            <GridColumn width={3} ><p style={{paddingRight: '2rem'}}><strong>{t("SUBJECT_TAB_DATA_INFO_DEFINITION_CODE", "Trigger")}</strong></p></GridColumn>
            <GridColumn width={13} ><TriggerInfoDisplay t={t} questionnaire={questionnaire}/></GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={3} ><p style={{paddingRight: '2rem'}}><strong>{t("SUBJECT_TAB_DATA_INFO_DEFINITION_WORKFLOW", "Submitted")}</strong></p></GridColumn>
            <GridColumn width={13} ><p>{questionnaire.completionDate && DateTimeService.build.asDisplayDateTime(
                questionnaire.completionDate
            )}</p></GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={3} ><p style={{paddingRight: '2rem'}}><strong>{t("SUBJECT_TAB_DATA_INFO_DEFINITION_WORKFLOW", "Active Window")}</strong></p></GridColumn>
            <GridColumn width={13} ><p>{`${DateTimeService.build.asDisplayDateTime(
                questionnaire.activationFromDate
            )} - ${DateTimeService.build.asDisplayDateTime(
                questionnaire.activationToDate
            )}`}</p></GridColumn>
          </GridRow>
        </Grid>
        <h3 style={{paddingTop: '2rem'}}>{t("Workflow")}</h3>
        <Grid>
          <GridRow>
            <GridColumn width={3} ><p style={{paddingRight: '2rem'}}><strong>{t("SUBJECT_TAB_DATA_INFO_DEFINITION_WORKFLOW", "Current Task")}</strong></p></GridColumn>
            <GridColumn width={13} ><p>{workflowHelper.getTaskFromQuestionnaire(questionnaire)?.code}</p></GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={3} ><p style={{paddingRight: '2rem'}}><strong>{t("SUBJECT_TAB_DATA_INFO_DEFINITION_WORKFLOW", "Task Type")}</strong></p></GridColumn>
            <GridColumn width={13} ><p>{workflowHelper.getTaskFromQuestionnaire(questionnaire)?.type}</p></GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={3} ><p style={{paddingRight: '2rem'}}><strong>{t("SUBJECT_TAB_DATA_INFO_DEFINITION_WORKFLOW", "Task Started")}</strong></p></GridColumn>
            <GridColumn width={13} ><p>{DateTimeService.build.asDisplayDateTime(
                workflowHelper.getTaskInstanceFromQuestionnaire(questionnaire).startDate
            )}</p></GridColumn>
          </GridRow>
          <GridRow>
            <GridColumn width={3} ><p style={{paddingRight: '2rem'}}><strong>{t("SUBJECT_TAB_DATA_INFO_DEFINITION_WORKFLOW", "Previous Tasks")}</strong></p></GridColumn>
            <GridColumn width={13} >
              <Grid>
              {workflowHelper.getInstanceFromQuestionnaire(questionnaire).questionnaireWorkflowTaskInstances
                  .sort((a,b)=>DateTime.fromISO(a.startDate)-DateTime.fromISO(b.startDate))
                  .map(qWTI=><><GridRow >
                    <GridColumn width={4}><strong>{qWTI.questionnaireWorkflowTask.code}</strong></GridColumn>
                    <GridColumn width={4}>{DateTimeService.build.asDisplayDateTime(
                        qWTI.startDate
                    )}</GridColumn>
                    <GridColumn width={4}>{qWTI.completionDate && DateTimeService.build.asDisplayDateTime(
                        qWTI.completionDate
                    )}</GridColumn>
                  </GridRow>
                    {qWTI.completionDate && <GridRow>
                      <GridColumn width={4} />
                      <GridColumn width={4}>{
                        qWTI.outcome
                      }</GridColumn>
                      <GridColumn width={4}>{
                        qWTI.authSource
                      }</GridColumn>
                    </GridRow>}
                  </>)}
              </Grid>
            </GridColumn>
          </GridRow>
        </Grid>
      </Modal.Content>
    </Modal>
  </>
}

export default withTranslation()(QuestionnaireInfoModalButton)