/* eslint no-unused-vars: 0 */

import React, {Component} from "react";
import { withTranslation } from "react-i18next";
import {
  Grid,
  Input,
  Label,
  Table,
  Checkbox,
  Button,
  Form,
  Dropdown,
} from "semantic-ui-react";
import SubjectService from "../../SubjectService";
import Page from "../../components/page/Page";
import CreateSubjectButton from "./CreateSubjectButton";
import TrialService from "../../TrialService";
import RSVP from "rsvp";
import ScoringService from "../../services/ScoringService";
import DisplayQuestion from "../../questionnaires/display/DisplayQuestion";
import AuthService from "../../services/AuthService";
import { uniq } from "lodash";
import LocalStorageHelper from "../../helpers/LocalStorageHelper";
import { CONTENT_TYPE } from "../../RequestHelper";
import ExportModal from "./ExportModal";
import PermissionsService from "../../services/PermissionsService";
import GroupPermission from "../../GroupPermission";
import exportConstants from "../../constants/EXPORT_MODAL";

class SubjectListPage extends Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      subjects: [],
      recordDefinitions: [],
      loading: true,
      groupLabel: "",
      shouldHideIfStopped: true,
      searchTerm: null,
      pageNum: 0,
      totalSubjects: 0,
      selectedSubjects: [],
      shouldLabelsRepeat: false,
      allSelected: false,
      sortBy: null,
      sortDirection: null,
      enabledFilters: [],
      showExportModal: false,
      hasManageSiteExport: false,
    };

    this.searchInputRef = React.createRef();
    this.checkForPermission();
    this.reload(false);
  }

  componentDidUpdate = async (prevProps) => {
    if (
      prevProps.match.params.groupCode !== this.props.match.params.groupCode
    ) {
      await this.setState({
        searchTerm: "",
        pageNum: 0,
        selectedSubjects: [],
        allSelected: false,
      });
      this.reload();
    }
  };

  reload = (pageInitialised = true) => {
    if (pageInitialised) {
      this.setState({ loading: true });
    }

    RSVP.Promise.all([
      TrialService.getConfiguration().then((config) => {
        this.setState({ config: config, configLoaded: true });
      }),
      SubjectService.listSubjects(
        this.props.match.params.groupCode,
        this.state.pageNum,
        this.state.searchTerm,
        this.state.sortBy,
        this.state.sortDirection,
        null,
        this.state.enabledFilters.join(",")
      ).then((subjectsData) => {
        const { pageSubjects: subjects, allSubjectIds } = subjectsData;
        let filteredSubjectIds = [...allSubjectIds];
        subjects.forEach((subject) => {
          let idxToRemove = -1;
          if (this.state.shouldHideIfStopped && subject.endDate) {
            idxToRemove = filteredSubjectIds.findIndex((id) => {
              return id === subject.id;
            });
            if (idxToRemove !== -1) {
              filteredSubjectIds.splice(idxToRemove, 1);
            }
          }
        });
        LocalStorageHelper.setJson("subjectListIds", filteredSubjectIds);
        this.setState({ subjects });
      }),
      SubjectService.getSubjectsCount(
        this.props.match.params.groupCode,
        this.state.pageNum,
        this.state.searchTerm,
        this.state.enabledFilters.join(",")
      ).then((count) => {
        this.setState({ totalSubjects: count });
      }),
      SubjectService.getSubjectRecordQuestionnaireDefinitions().then(
        (recordDefinitions) => {
          this.setState({ recordDefinitions });
        }
      ),
      TrialService.getGroups().then((groups) => {
        groups.forEach((group) => {
          if (group.code === this.props.match.params.groupCode) {
            this.setState({ groupLabel: group.label });
          }
        });
      }),
    ]).finally(() => {
      this.setState({ loading: false });
    });
  };

  checkForPermission = async () => {
    const hasManageSiteExport = await PermissionsService.hasPermissionInGroup(this.props.match.params.groupCode, GroupPermission.MANAGE_SITE_EXPORTS);
    this.setState({ hasManageSiteExport });
  };

  handleExportModalClose = () => {
    this.setState({showExportModal:false})
  };

  openExportModal = () => {
    this.setState({showExportModal:true})
  }

  handleShouldLabelsRepeat = (e, v) => {
    this.setState({ shouldLabelsRepeat: v.checked });
  };

  downloadFile = (blob, fileName) => {
    const link = document.createElement("a");
    // create a blobURI pointing to our Blob
    link.href = URL.createObjectURL(blob);
    link.download = fileName;
    // some browser needs the anchor to be in the doc
    document.body.append(link);
    link.click();
    link.remove();
    // in case the Blob uses a lot of memory
    setTimeout(() => URL.revokeObjectURL(link.href), 7000);
  };

  createLabels = (e) => {
    const subjectString = this.state.selectedSubjects.toString();

    fetch(
      process.env.REACT_APP_SERVER_ADDRESS +
        "/templates/generate-labels?subjectIds=" +
        subjectString +
        "&singleLabel=" +
        !this.state.shouldLabelsRepeat,
      {
        method: "GET",
        headers: {
          "Content-Type": CONTENT_TYPE.APPLICATION_OCTETSTREAM,
          Authorization: "Bearer " + AuthService.getAuthToken(),
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error in response");
        }
        return response.blob();
      })
      .then((blob) => {
        this.downloadFile(blob, "labels.docx");
      })
      .catch((error) => {
        console.error(error);
      });
  };

  onChangeSearch = (e) => {
    this.setState({ searchTerm: e.target.value });
  };

  onChangeSort = (_e, { value: [sortBy, sortDirection] }) => {
    this.setState({ sortBy, sortDirection }, this.reload);
  };

  onChangeFilter = ({ value: enabledFilters }, isSingle) => {
    if (isSingle) {
      enabledFilters = [enabledFilters];
    }
    this.setState({ enabledFilters }, this.reload);
  };

  submitSearch = () => {
    if (this.searchInputRef.current.value === "") {
      this.setState({ searchTerm: null });
    }
    this.reload();
  };

  nextPage = async () => {
    await this.setState((prevState) => {
      return { pageNum: prevState.pageNum + 1 };
    });
    this.reload();
  };

  prevPage = async () => {
    if (this.state.pageNum !== 0) {
      await this.setState((prevState) => {
        return { pageNum: prevState.pageNum - 1 };
      });
      this.reload();
    }
  };

  captureEnterKey = (e) => {
    if (e.key === "Enter") {
      this.submitSearch();
    }
  };

  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions
  escapeRegExp = function (string) {
    return string.replace(/[.*+\-?^${}()|[\]\\]/g, "\\$&");
  };

  handleHide = () => {
    this.setState((prevState) => {
      return { shouldHideIfStopped: !prevState.shouldHideIfStopped };
    });
    this.reload(false);
  };

  clickCheckbox = (event, target, subjectId) => {
    event.stopPropagation();
    let subjectIndex = this.state.selectedSubjects.findIndex((s) => {
      return s === subjectId;
    });
    if (target.checked) {
      if (subjectIndex === -1) {
        this.setState((oldState) => {
          const selectedSubjects = [...oldState.selectedSubjects];
          selectedSubjects.push(subjectId);
          return { selectedSubjects };
        }, this.checkAreAllSelected);
      }
    } else {
      if (subjectIndex !== -1) {
        this.setState((oldState) => {
          const selectedSubjects = [...oldState.selectedSubjects];
          selectedSubjects.splice(subjectIndex, 1);
          return { selectedSubjects };
        }, this.checkAreAllSelected);
      }
    }
  };

  checkAreAllSelected = () => {
    if (this.state.subjects.length === 0) {
      this.setState({ allSelected: false });
      return;
    }

    const areAllSelected = this.state.subjects.every((s) => {
      return this.state.selectedSubjects.some((id) => s.id === id);
    });

    if (areAllSelected !== this.state.allSelected) {
      this.setState({ allSelected: areAllSelected });
    }
  };

  toggleAllSelected = (_e, checkbox) => {
    if (checkbox.checked) {
      this.setState({
        selectedSubjects: uniq([
          ...this.state.selectedSubjects,
          ...this.state.subjects.map((s) => s.id),
        ]),
        allSelected: true,
      });
    } else {
      this.setState({ selectedSubjects: [], allSelected: false });
    }
  };
  
  render() {
    const { t, history } = this.props;
    const groupCode = this.props.match.params.groupCode;

    const paginationLimit = this.state?.config?.ui?.subjectPaginationLimit
      ? this.state?.config?.ui?.subjectPaginationLimit
      : 25;

    const subjectTablePageSearch = this.state?.config?.ui
      ?.subjectTablePageSearch
      ? true
      : false;

    const tableColumnsConfig = Window.configuration.ui.subjectTableColumns || [
      "subjectCode",
      "groups",
    ];
    const tableRowColoring = Window.configuration.ui.subjectTableColoring || [];

    const subjectFilters =
      Window.configuration.ui?.subjectFilter?.filters || [];
    const subjectFilterSingle =
      Window.configuration.ui?.subjectFilter?.single === "true";
    const subjectFilterOptions = subjectFilters.map((filter) => {
      return {
        key: filter.code,
        text: t("SUBJECT_LIST_FILTER_LABEL_" + filter.code.toUpperCase()),
        value: filter.code,
      };
    });

    const keyDisplay = tableRowColoring.map((colorObj, i) => {
      return (
        <div style={{ display: "flex", alignItems: "center", padding: "8px" }}>
          <div
            style={{
              height: "20px",
              width: "20px",
              backgroundColor: colorObj.color,
              borderRadius: "4px",
            }}
          />
          <div style={{ padding: "0 8px" }}>{t("COLOURING_KEY_DESC_" + i)}</div>
        </div>
      );
    });

    let headerCells = tableColumnsConfig.map((columnConfig) => {
      const prefix = columnConfig.split("_")[0];
      const suffix = columnConfig.split("_")[1];

      let headerCellContent = "";
      if (prefix === "subjectCode") {
        headerCellContent = t("SUBJECT_LIST_TABLE_HEADER_SUBJECT_CODE");
      } else if (prefix === "compliance") {
        headerCellContent = t("COHORT_COMPLIANCE_" + suffix.toUpperCase());
      } else if (prefix === "groups") {
        headerCellContent = t("SUBJECT_LIST_TABLE_HEADER_GROUPS");
      } else if (prefix === "checkbox") {
        headerCellContent = (
          <Checkbox
            onClick={(e, v) => {
              this.toggleAllSelected(e, v);
            }}
            checked={this.state.subjects.every((s) => {
              return this.state.selectedSubjects.some((id) => s.id === id);
            })}
          />
        );
      } else {
        const subjectRecordDefinition = this.state.recordDefinitions.find(
          (recordDef) => recordDef.code === prefix
        );
        if (subjectRecordDefinition) {
          const questionDefiniton = subjectRecordDefinition.questions.find(
            (question) => question.code === suffix
          );
          if (questionDefiniton) {
            headerCellContent = questionDefiniton.label;
          } else {
            console.error(
              "Could not find question definiton with code " + suffix + " in ",
              subjectRecordDefinition
            );
          }
        }
      }

      return (
        <Table.HeaderCell key={columnConfig}>
          {headerCellContent}
        </Table.HeaderCell>
      );
    });

    let searchRegex = this.state.searchTerm
      ? new RegExp(this.escapeRegExp(this.state.searchTerm), "i")
      : null;

    const subjectRows = this.state.subjects.map((subject) => {
      let shouldShow =
        subjectTablePageSearch && this.state.searchTerm ? false : true;
      let subjectRecordsMap = {};
      subject.subjectRecords.forEach((subjectRecord) => {
        for (const [key, value] of Object.entries(subjectRecord.answers)) {
          subjectRecordsMap[subjectRecord.definition.code + "_" + key] = value;
        }
      });

      const cells = tableColumnsConfig.map((columnConfig) => {
        let content = "";

        const prefix = columnConfig.split("_")[0];
        const suffix = columnConfig.split("_")[1];

        if (prefix === "subjectCode") {
          content = subject.subjectCode;
        } else if (prefix === "checkbox") {
          content = (
            <Checkbox
              onClick={(e, v) => {
                this.clickCheckbox(e, v, subject.id);
              }}
              checked={this.state.selectedSubjects.some(
                (id) => id === subject.id
              )}
            />
          );
        } else if (prefix === "compliance") {
          content = subject.compliance
            ? Math.round(subject.compliance[suffix + "Compliance"]) + "%"
            : "";
        } else if (prefix === "groups") {
          content = subject.groups.map((group) => (
            <Label key={group.id}>{group.label}</Label>
          ));
        } else {
          // Look up value from subject records
          const record = subject.subjectRecords.find(
            (record) => record.definition.code === prefix
          );
          if (record) {
            const questionDefinition = record.definition.questions.find(
              (qDef) => qDef.code === suffix
            );
            content = (
              <DisplayQuestion
                question={questionDefinition}
                answer={record.answers[suffix]}
                subjectId={subject.id}
                showLabel={false}
              />
            );
          }
        }

        if (
          subjectTablePageSearch &&
          this.state.searchTerm &&
          typeof content === "string"
        ) {
          if (searchRegex.test(content)) {
            shouldShow = true;
          }
        }

        // Currently, endDate will never be in the future.
        if (this.state.shouldHideIfStopped && subject.endDate) {
          shouldShow = false;
        }

        return (
          <Table.Cell key={subject.id + "-" + columnConfig}>
            {content}
          </Table.Cell>
        );
      });

      if (shouldShow) {
        let backgroundColor = null;
        const scoringInformation = ScoringService.getScoringInformation(
          null,
          tableRowColoring,
          subjectRecordsMap
        );
        if (scoringInformation) {
          backgroundColor = scoringInformation.color;
        }
        return (
          <Table.Row
            style={{ cursor: "pointer", backgroundColor }}
            onClick={() => {
              history.push(
                "/app/subject/" + subject.id + "/tabs/subject-record/details"
              );
            }}
            key={subject.id}
          >
            {cells}
          </Table.Row>
        );
      }
      return null;
    });

    const sortOptions = (() => {
      let options = [];

      const pushOption = (type, direction) => {
        options.push({
          key: type + "_" + direction,
          text: t("SUBJECT_LIST_SORT_BY_" + type + "_" + direction),
          value: [type, direction],
        });
      };

      const sortTypes = this.state.config?.ui?.sortOptions || [
        "SUBJECT_CODE",
        "DATE_CONSENTED",
        "PRO_LAST_COMPLETED",
      ];
      sortTypes.forEach((type) => {
        if (type.endsWith("_ASC")) {
          pushOption(type.substring(0, type.length - 4), "ASC");
          return;
        }
        if (type.endsWith("_DESC")) {
          pushOption(type.substring(0, type.length - 5), "DESC");
          return;
        }
        ["ASC", "DESC"].forEach((direction) => {
          pushOption(type, direction);
        });
      });

      if (
        this.state.configLoaded &&
        (this.state.sortBy == null || this.state.sortDirection == null)
      ) {
        const [type, direction] = options[0].value;
        this.setState({ sortBy: type, sortDirection: direction }, this.reload);
      }
      
      return options;
    })();

    const hasResults = subjectRows.some((row) => row != null);

    return (
      <Page
        name="SUBJECT_LIST"
        header={t("SUBJECT_LIST_HEADER")}
        subheader={t("SUBJECT_LIST_SUBHEADER", {
          groupLabel: this.state.groupLabel,
        })}
        loading={this.state.loading}
      >
        <Grid>
          <Grid.Column floated="left" width={4}>
            <CreateSubjectButton groupCode={groupCode} />
          </Grid.Column>
          <Grid.Column
            floated="right"
            width={12}
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
            }}
          >
            {this.state.hasManageSiteExport && (<Button
                color={
                  this.state.selectedSubjects.length === 0 ? "gray" : this.state.selectedSubjects.length <= exportConstants.MAX_SUBJECT_SELECTION_COUNT ? "orange" : "gray"
                }
                onClick={this.openExportModal}
            >
              {t("SUBJECT_LIST_EXPORT_BUTTON", "Export Data")}
            </Button>)}
            {this.state.config?.ui?.showLabelDownload && (
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", padding: "0 20px" }}>
                  <Checkbox
                    label={t("REPEAT_LABELS")}
                    checked={this.state.shouldLabelsRepeat}
                    onChange={this.handleShouldLabelsRepeat}
                    style={{ flex: 1, alignSelf: "center" }}
                  />
                </div>
                <Button
                  disabled={this.state.selectedSubjects.length === 0}
                  color={
                    this.state.selectedSubjects.length === 0 ? "gray" : "orange"
                  }
                  onClick={this.createLabels}
                >
                  {t("CREATE_LABELS")}
                </Button>
              </div>
            )}
            <Form onSubmit={this.submitSearch}>
              <Input
                ref={this.searchInputRef}
                onChange={this.onChangeSearch}
                icon="search2"
                style={{ paddingRight: "120px" }}
                value={this.state.searchTerm}
                placeholder={t("GENERIC_TERM_SEARCH")}
                action={t("GENERIC_TERM_SEARCH")}
              />
            </Form>

            <div style={{ display: "flex", padding: "0 20px" }}>
              <Checkbox 
                id="hide-stopped"
                label={t("HIDE_STOPPED")}
                checked={this.state.shouldHideIfStopped}
                onChange={this.handleHide}
              />
            </div>
          </Grid.Column>
        </Grid>

        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "space-around",
            padding: "8px",
            flexWrap: "wrap",
          }}
        >
          {keyDisplay}
        </div>

        <Grid>
          <Grid.Column floated="left" width={4} textAlign={"left"}>
            <label>{t("SUBJECT_LIST_SORT_BY_LABEL")}</label>
            <Dropdown
              placeholder={t("SUBJECT_LIST_SORT_BY_PLACEHOLDER")}
              onChange={this.onChangeSort}
              fluid
              selection
              options={sortOptions}
              defaultValue={[this.state.sortBy, this.state.sortDirection]}
            />
          </Grid.Column>

          <Grid.Column floated="left" width={4} textAlign={"left"}>
            {subjectFilterOptions.length > 0 && (
              <>
                <label>{t("SUBJECT_LIST_FILTER_LABEL")}</label>
                <div style={{ textAlign: "center" }}>
                  {subjectFilterSingle ? (
                    <Dropdown
                      placeholder={t("SUBJECT_LIST_FILTER_PLACEHOLDER")}
                      onChange={(_e, data) => this.onChangeFilter(data, true)}
                      fluid
                      multiple={false}
                      selection
                      clearable={true}
                      options={subjectFilterOptions}
                      defaultValue={this.state.enabledFilters[0]}
                    />
                  ) : (
                    <Dropdown
                      placeholder={t("SUBJECT_LIST_FILTER_PLACEHOLDER")}
                      onChange={(_e, data) => this.onChangeFilter(data, false)}
                      fluid
                      multiple={true}
                      selection
                      options={subjectFilterOptions}
                      defaultValue={this.state.enabledFilters}
                    />
                  )}
                </div>
              </>
            )}
          </Grid.Column>

          <Grid.Column floated="right" width={4} textAlign={"right"}>
            <Button onClick={this.prevPage} disabled={this.state.pageNum === 0}>
              {t("GLOBAL_BUTTON_PREVIOUS")}
            </Button>
            <Button
              onClick={this.nextPage}
              disabled={
                Math.floor(this.state.totalSubjects / paginationLimit) <=
                this.state.pageNum
              }
            >
              {t("GLOBAL_BUTTON_NEXT")}
            </Button>
            Total: {this.state.totalSubjects}
          </Grid.Column>
        </Grid>

        <Table selectable columns={5}>
          <Table.Header>
            <Table.Row>{headerCells}</Table.Row>
          </Table.Header>
          <Table.Body>{subjectRows}</Table.Body>     
        </Table>

        {!hasResults && (
          <div style={{padding: "14px", textAlign: "center"}}>
            <p style={{ fontSize: "20px", letterSpacing: "1px" }}>
              {t(
                'NO_RESULTS_SUBJECT',
                'No results found'
              )}
            </p>
          </div>
        )}

        <CreateSubjectButton groupCode={groupCode} />
        <ExportModal
            show={this.state.showExportModal}
            onClose={this.handleExportModalClose}
            selectedSubjects={this.state.selectedSubjects}
            groupCode={groupCode}
            modalTitle={t('SUBJECT_LIST_EXPORT_DATA_GROUP_MODAL_TITLE',"Exporting data from Site: ") + this.state.groupLabel}
            t={t}
            closeIcon
        />
      </Page>
    );
  }
}

export default withTranslation()(SubjectListPage);
