import AuthService from './AuthService';
import fetchProgress from 'fetch-progress';
import RequestHelper from '../RequestHelper';
import InternationalisationService from '../InternationalisationService';
import { QUESTION_TYPES } from 'atom5-branching-questionnaire';
import { CONTENT_TYPE } from '../RequestHelper';
import moment from "moment/moment";
import RSVP from "rsvp";

// Matches AttachmentState Enum in API
export const MEDIA_STATE = {
  NEW: {
    translationKey: 'MEDIA_UPLOAD_STATE_NEW',
    fallbackText:
      'We are waiting for the file to be uploaded. The time this takes depends on the duration of the video, the file size, and network conditions.',
    displayProperties: {
      isInfo: true,
      icon: 'info circle'
    },
    attachmentSearch: {
      translationKey: 'MEDIA_UPLOAD_STATE_NEW_SEARCH_FILTER',
      fallbackText: 'Awaiting upload'
    }
  },
  RECEIVED: {
    translationKey: 'MEDIA_UPLOAD_STATE_RECEIVED',
    fallbackText:
      'We have received the file, and it is in the queue to be processed.',
    displayProperties: {
      isInfo: true,
      icon: 'file outline icon'
    },
    attachmentSearch: {
      translationKey: 'MEDIA_UPLOAD_STATE_RECEIVED_SEARCH_FILTER',
      fallbackText: 'Uploaded'
    }
  },
  ORPHAN: {
    // Intentionally the same as RECEIVED - the difference in the states is a server side difference, to the user there is no difference.
    translationKey: 'MEDIA_UPLOAD_STATE_RECEIVED',
    fallbackText:
      'We have received the file, and it is in the queue to be processed.',
    displayProperties: {
      isInfo: true,
      icon: 'file outline icon'
    },
    attachmentSearch: {
      showInFilter: false
    }
  },
  READY_FOR_PROCESSING: {
    // Intentionally the same as RECEIVED - the difference in the states is a server side difference, to the user there is no difference.
    translationKey: 'MEDIA_UPLOAD_STATE_RECEIVED',
    fallbackText:
      'We have received the file, and it is in the queue to be processed.',
    displayProperties: {
      isInfo: true,
      icon: 'file outline icon'
    },
    attachmentSearch: {
      showInFilter: false
    }
  },
  PROCESSING: {
    translationKey: 'MEDIA_UPLOAD_STATE_PROCESSING',
    fallbackText: 'The file is being processed.',
    displayProperties: {
      isInfo: true,
      icon: 'spinner icon'
    },
    attachmentSearch: {
      translationKey: 'MEDIA_UPLOAD_STATE_PROCESSING_SEARCH_FILTER',
      fallbackText: 'Processing'
    }
  },
  COMPLETED: {
    translationKey: 'MEDIA_UPLOAD_STATE_COMPLETED',
    fallbackText: 'This file upload is complete.',
    attachmentSearch: {
      translationKey: 'MEDIA_UPLOAD_STATE_COMPLETED_SEARCH_FILTER',
      fallbackText: 'Ready'
    }
  },
  FAILED: {
    translationKey: 'MEDIA_UPLOAD_STATE_FAILED',
    fallbackText: 'The processing of the file encountered too many errors.',
    displayProperties: {
      icon: 'exclamation triangle icon'
    },
    attachmentSearch: {
      translationKey: 'MEDIA_UPLOAD_STATE_FAILED_SEARCH_FILTER',
      fallbackText: 'Failed'
    }
  },
  LEGACY: {
    attachmentSearch: {
      showInFilter: false // We treat this the same as COMPLETED
    }
  }
};

export const INTERNAL_WORKFLOW_STATUS = {
  // The workflowStatus column has no value i.e. null or blank string for items which are not set, we need to be able to see these.
  '_NOT_SPECIFIED_': {
    value: '_NOT_SPECIFIED_',
  },
  // Ignore the workflow status in the search
  '_ANY_': {
    value: '_ANY_',
  }
};

const AttachmentService = {
  getMediaState: (response) => {
    const headerValue = response.headers.get('X-Attachment-State');
    const state = AttachmentService.getMediaStateFromString(headerValue);
    return state;
  },
  getMediaFilenameFromHeader: (response) => {
    return response.headers.get('X-Attachment-Filename');
  },
  getMediaStateFromString: (stringState) => {
    const state = MEDIA_STATE[stringState];
    return state;
  },
  getAttachmentInline: async (
    subjectId,
    reference,
    blurredOnly,
    progressCallbackHandler,
    responseCallbackHandler,
    errorCallbackHandler
  ) => {

    const requestPromise = AttachmentService.buildRestRequest.bind(
        this,
        subjectId,
        reference,
        blurredOnly,
        progressCallbackHandler,
        responseCallbackHandler,
        errorCallbackHandler
    );

    if (AuthService.getTokenExpiry().isBefore(moment())) {
      return AuthService.refreshAccessToken().then(requestPromise);
    } else {
      return requestPromise();
    }
  },
  isPermittedToViewAttachment: (subjectId, reference) => {
    const url = process.env.REACT_APP_SERVER_ADDRESS +
    '/subjects/' +
    subjectId +
    '/attachments/' +
    reference +
    '/permitted';
    return RequestHelper.send(
      url,
      {
        'Accept': CONTENT_TYPE.TEXT_PLAIN
      },
      'GET'
    );
  },
  getAttachment: async (subjectId, reference, blurredOnly) => {
    let url;
    if (blurredOnly) {
      url =
        process.env.REACT_APP_SERVER_ADDRESS +
        '/subjects/' +
        subjectId +
        '/attachments/blurred/' +
        reference;
    } else {
      url =
        process.env.REACT_APP_SERVER_ADDRESS +
        '/subjects/' +
        subjectId +
        '/attachments/' +
        reference;
    }
    const response = await RequestHelper.send(
      url,
      { 'Accept-Language': InternationalisationService.getLanguage() },
      'GET',
      null
    );
    return response;
  },
  getIcon: (question) => {
    let iconAppend = '';
    switch (question.type) {
      case QUESTION_TYPES.VIDEO:
        iconAppend = ' video';
        break;
      case QUESTION_TYPES.IMAGE:
        iconAppend = ' image';
        break;
      default:
        iconAppend = '';
    }
    return 'file' + iconAppend;
  },
  updateWorkflowStatus: async (attachmentId, workflowStatus) => {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/attachments/updateWorkflowStatus`;
    const requestBody = { attachmentId, workflowStatus };
    return RequestHelper.send(
      url,
      { 'Accept-Language': InternationalisationService.getLanguage() },
      'POST',
      null,
      requestBody
    );
  },
  getSearchCriteriaRequestBody:  (searchCriteria) => {
    const hasPrimaryQuestionnaireAnswerValueSearchCriteria = searchCriteria?.primaryQuestionnaireAnswerValueSearchCriteria != null && searchCriteria?.primaryQuestionnaireAnswerValueSearchCriteria?.value != null && searchCriteria?.primaryQuestionnaireAnswerValueSearchCriteria?.value.trim().length > 0;
    const requestBody = {
        attachmentState:
          searchCriteria?.attachmentState != null
            ? searchCriteria.attachmentState
            : 'COMPLETED',
        subjectCodes:
          searchCriteria?.subjects != null
            ? searchCriteria.subjects.map(subject => subject.subjectCode)
            : [],
        questionnaireDefinitionCodes:
          searchCriteria?.questionnaireDefinitionCodes != null
            ? searchCriteria.questionnaireDefinitionCodes
            : [],
        attachmentWorkflowStatus:
          searchCriteria?.attachmentWorkflowStatus != null && searchCriteria?.attachmentWorkflowStatus !== ''
            ? searchCriteria.attachmentWorkflowStatus
            : INTERNAL_WORKFLOW_STATUS._ANY_.value,
        primaryQuestionnaireIds:
          searchCriteria?.primaryQuestionnaireId != null
            ? [searchCriteria?.primaryQuestionnaireId]
            : [],
        primaryQuestionnaireAnswerValueSearchCriteria:
          hasPrimaryQuestionnaireAnswerValueSearchCriteria
            ? [searchCriteria?.primaryQuestionnaireAnswerValueSearchCriteria]
            : []
      };
      return requestBody;
  },
  getBySearchCriteria: async (searchCriteria) => {
    const url = `${process.env.REACT_APP_SERVER_ADDRESS}/attachments/search`;
    const requestBody = AttachmentService.getSearchCriteriaRequestBody(searchCriteria);
    const response = await RequestHelper.send(
      url,
      { 'Accept-Language': InternationalisationService.getLanguage() },
      'POST',
      null,
      requestBody
    );
    return response;
  },
  extractAdditionalData: (attachment, additionalDataKey) => {
    if (!attachment?.additionalData || !additionalDataKey) {
      return undefined;
    }
    const value = attachment.additionalData[additionalDataKey];
    return value;
  },
  buildRestRequest: (subjectId,
    reference,
    blurredOnly,
    progressCallbackHandler,
    responseCallbackHandler,
    errorCallbackHandler) => {
    return new RSVP.Promise((resolve, reject) => {

      let url;
      if (blurredOnly) {
        url =
          process.env.REACT_APP_SERVER_ADDRESS +
          '/subjects/' +
          subjectId +
          '/attachments/blurred/' +
          reference +
          '/inline';
      } else {
        url =
          process.env.REACT_APP_SERVER_ADDRESS +
          '/subjects/' +
          subjectId +
          '/attachments/' +
          reference +
          '/inline';
      }
      fetch(url, {
        headers: {
          Authorization: 'Bearer ' + AuthService.getAuthToken()
        }
      })
        .then(
          fetchProgress({
            onProgress(progress) {
              progressCallbackHandler(progress);
            },
            onError(err) {
              errorCallbackHandler(err);
            }
          })
        )
        .then(async (response) => {
          if (!response.ok) {
            throw new Error('Error in response');
          }
          const state = AttachmentService.getMediaState(response);
          const filename = AttachmentService.getMediaFilenameFromHeader(response);
          const blob = await response.blob();
          return { blob, state, filename};
        })
        .then((mediaData) => {
          const mediaDataUrl = URL.createObjectURL(mediaData.blob);
          responseCallbackHandler(mediaDataUrl, mediaData.blob, mediaData.filename);
        })
        .catch((err) => {
          errorCallbackHandler(err);
        });
    });
  }
};

export default AttachmentService;
