import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import {
    Button,
    Grid, Loader,
    Message,
    Table,
} from "semantic-ui-react";
import Page from "../../components/page/Page";
import {useHistory, useParams} from "react-router-dom";
import DashboardReportService from "../../services/DashboardReportService";
import {DateTime} from "luxon";
import DateRangeControls from "../../components/DateRangeControls";
import moment from "moment";
import FileSaver from 'file-saver';
import AparitoSwitch from "../../components/questionnaire/AparitoSwitch";


const DashboardReportPage = ({ t }) => {

    const params = useParams();

    const [startDate, setStartDate] = useState(DateTime.now().minus({weeks: 2}).toISODate())
    const [endDate, setEndDate] = useState(DateTime.now().toISODate())
    const handleDateChange = (startDate, endDate) => {
        setStartDate(startDate);
        setEndDate(endDate);
    };

    const history = useHistory();

    const [dashboardReportTitle, setDashboardReportTitle] = useState("");

    const [tabs, setTabs] = useState([]);
    const [selectedTab, setSelectedTab] = useState(null);

    const [exportByDate, setExportByDate] = useState(false);
    const [loading, setLoading] = useState(true);

    useEffect(()=>{
        const getTabsForReport = async () => {
            const tabs = await DashboardReportService.getDashboardReportTabList(params.code);
            setTabs(tabs);
            if(tabs.length > 0){
                setSelectedTab(tabs[0].code)
            }
        }
        getTabsForReport()
    }, [params]);

    const [dashboardReport, setDashboardReport] = useState();
    useEffect(() => {
        const getDashboardReport = async () => {
            try {
                setLoading(true)
                const reportInformation = await Promise.all([
                    selectedTab != null ?
                        DashboardReportService.getDashboardReportOfTab(params.code, startDate, endDate, 300, selectedTab) :
                        // DashboardReportService.getDashboardReport(params.code, startDate, endDate),
                        [], // todo no data until tab selected - refactor once All option is made configurable
                    DashboardReportService.getDashboardReportList()
                ]);

                if (!reportInformation || reportInformation.length === 0) {
                    console.error("Report not found", params.code)
                }
                const report = reportInformation ? reportInformation[0] : undefined
                setDashboardReport(report);
                setDashboardReportTitle(report?.title)
            }catch (e){
                console.log('Error loading dashboard reports', e)
            }finally{
                setLoading(false)
            }
        }

        getDashboardReport();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [params, startDate, endDate, selectedTab]);

    const handleExportByDate = () => {
        setExportByDate(!exportByDate);
    }

    const handleExport = async () => {
        try{
            let jsonContent;
            if (exportByDate) {
                jsonContent = await DashboardReportService.getDashboardReportExportByDate(params.code, startDate, endDate);
            }else{
                jsonContent = await DashboardReportService.getDashboardReportExport(params.code)
            }
            let blob = new Blob([jsonContent], {
                type: "application/octet-stream"
            });
            let jsDate = new Date();
            //jsDate.setTime( jsDate.getTime() + jsDate.getTimezoneOffset() * 60 * 1000 );
            FileSaver.saveAs(blob, "dashboard-report-export-" + jsDate.toISOString().slice(0, 19) + ".xls");
        }catch(response){
            console.log("Error exporting Excel Dashboard report:" + response);
        }
    }

    const createTable = (report) => {
        if (!report || !report.headers || !report.rows || report.rows.length===0) {
            return;
        }

        const headerStyle = { textOverflow: "ellipses", whiteSpace: "nowrap", width: '100px' };
        const cellStyle = { textOverflow: "ellipses", whiteSpace: "nowrap", color: 'black', cursor:"pointer" };
        const headerRow = (
            <Table.Header>
                {report.headers.map((header) => (
                    <Table.HeaderCell style={headerStyle}>
                        {t(header, header)}
                    </Table.HeaderCell>
                ))}
            </Table.Header>
        );
        const reportRows = report.rows.map((row, index) => {
            const link = `/app/subject/${row.subjectId}/questionnaire-type/${row.questionnaireType}/${row.questionnaireDefinitionCode}/${row.isComplete?"view":"submit"}/${row.questionnaireId}`;
            return (

                <Table.Row
                    key={index}
                    style={cellStyle}
                    onClick={()=>history.push(link)}
                >
                    {row.values.map((row)=><Table.Cell
                        style={{...cellStyle, backgroundColor: row.color}}
                    >{row.value}</Table.Cell>)}
                </Table.Row>

            )
        });
        return (
            <Table>
                {headerRow}
                {reportRows}
            </Table>
        );
    };

    return (
        <Page
            name=""
            header={t(dashboardReportTitle)}
        >
            <Grid style={{width: '100%'}}>
                <Grid.Row>
                    <Grid.Column width={16} >
                        <p style={{width: '100%', textAlign: 'center'}}>{t("DASHBOARD_REPORT_COMPLETION_DATE_RANGE", "Completion Date Range")}</p>
                        <DateRangeControls
                            onChange={handleDateChange}
                            start={moment(startDate)}
                            end={moment(endDate)}
                        />
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    <Grid.Column width={12}>
                        {/*todo removed for recursion as its a first customer for this feature*/}
                        {/*todo once needed to a different study, make configurable if All option is available*/}
                        {/*<Button*/}
                        {/*    primary*/}
                        {/*    className={"no-outline"}*/}
                        {/*    basic={selectedTab !== null}*/}
                        {/*    onClick={()=>{setSelectedTab(null)}}*/}
                        {/*>*/}
                        {/*    {t('DASHBOARD_REPORT_TAB_ALL','All')}*/}
                        {/*</Button>*/}
                        {tabs.map((tab, i)=>
                            <Button
                                primary
                                className={"no-outline"}
                                basic={tab.code !== selectedTab}
                                onClick={()=>{setSelectedTab(tab.code)}}
                            >
                                {t(tab.label)}
                            </Button>)}
                    </Grid.Column>
                    <Grid.Column width={4}>
                        <div style={{border: '1px solid', margin: 5,padding:3,borderRadius:'5px',borderColor:'darkgray'}}>
                            <div style={{display:'flex',justifyContent:'center', marginBottom: 5}}>
                                <AparitoSwitch id="aparitoSwitch"
                                               height={15}
                                               handleDiameter={17}
                                               checked={exportByDate}
                                               onChange={handleExportByDate}
                                               label={t("DASHBOARD_REPORT_EXPORT_BY_DATE", "Limit to Date Range")} />
                            </div>
                            <Button primary fluid onClick={handleExport} >{t("DASHBOARD_REPORT_EXPORT", "Export in Excel")}</Button>

                        </div>
                    </Grid.Column>
                </Grid.Row>
                <Grid.Row>
                    {!loading &&
                        <Grid.Column width={16}>
                            {dashboardReport?.rows?.length === 300 && <Message>{t("DASHBOARD_REPORT_LIMIT_WARNING", "Page limit reached, please reduce date range or download all data.")}</Message> }
                        </Grid.Column>
                    }
                </Grid.Row>
                <Grid.Row>
                    {loading && (<Loader active={loading} />)}
                    {!loading &&
                        <Grid.Column width={16}>
                            <div style={{display: 'flex', flexShrink: 1, overflowX: 'scroll', maxWidth: '100%'}}>
                                {createTable(dashboardReport)}
                            </div>
                        </Grid.Column>
                    }

                </Grid.Row>
            </Grid>
        </Page>
    );
};

export default withTranslation()(DashboardReportPage);
