import React, { memo, useMemo, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { Label, Menu, Popup } from 'semantic-ui-react';
import GeneralHelpers from '../../../../helpers/GeneralHelpers';
import ControlButton from './ControlButton';
import LayoutHelpers from '../../../../helpers/LayoutHelpers';
import { useFullScreen } from '../../../../context/FullScreenContext';

const TimeSkipControls = ({
  t,
  controlState,
  timeSkipOptions,
  onSizeChange,
  onSkip
}) => {
  const fullScreenContext = useFullScreen();
  const [menuPopupPosition, setMenuPopupPosition] = useState(false);

  const selectedSize = controlState.timeSkipSize || 1;

  const availableSizes = timeSkipOptions?.sizes || [0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1, 2, 5, 10, 20, 30, 60];
  if (!availableSizes.includes(selectedSize)) {
    availableSizes.push(selectedSize);
  }

  const formatValueForOutput = (value) => {
    return `${value}s`;
  }

  const buildStepSizeList = () => {
    return availableSizes.map(v => {
      return {
        key: v,
        value: v, // key is always undefined on onItemClick, so adding this to pick it up there. key is used for react rendering only
        content: formatValueForOutput(v),
        active: (v === selectedSize)
      }
    })
  }

  const stepSizeList = useMemo(buildStepSizeList, [availableSizes, selectedSize]);

  const handleSizeChange = (e, size) => {
    GeneralHelpers.stopEvent(e);
    if (onSizeChange != null) {
      onSizeChange(size);
    }
  };

  const handleSkipForwards = () => {
    if (onSkip != null) {
      onSkip(controlState.timeSkipSize);
    }
  };

  const handleSkipBackwards = () => {
    if (onSkip != null) {
      onSkip(-controlState.timeSkipSize);
    }
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const buildLabelComponent = () => {
    return (<Label
      size='medium'
      style={{ paddingLeft: 16, paddingRight: 16, paddingTop: 4, paddingBottom: 4 }}
      className='mouse-pointer'
      onClick={handleMenuPopupClick}
    >
      {formatValueForOutput(selectedSize)}
    </Label>);
  }

  const handleMenuPopupClick = (e) => {
    GeneralHelpers.stopEvent(e);
    setIsPopupOpen(true)
    setMenuPopupPosition(LayoutHelpers.createRectFromEvent(e));
  };

  const labelComponent = buildLabelComponent();

  const backButtonText = t(['ENHANCED_VIDEO_PLAYER_CONTROLS_TIMESKIP_BUTTON_STEP_BACKWARDS', 'COMPONENT_VIDEOPLAYER_SKIP_STEP_BACKWARDS'], 'Skip backwards {time}')
    .replace('{time}', `${controlState.timeSkipSize}s`);
  const forwardButtonText = t(['ENHANCED_VIDEO_PLAYER_CONTROLS_TIMESKIP_BUTTON_STEP_FORWARDS', 'COMPONENT_VIDEOPLAYER_SKIP_STEP_FORWARDS'], 'Skip forwards {time}')
    .replace('{time}', `${controlState.timeSkipSize}s`);

  const popupMountNode = fullScreenContext?.containerRef?.current || document.body;

  return (
    <>
      <ControlButton
        buttonText={backButtonText}
        iconName={'step-backward'}
        action={'skip'}
        onClick={handleSkipBackwards}
      />
      <Popup
        mountNode={popupMountNode}
        trigger={labelComponent}
        on='hover'
        content={t(['ENHANCED_VIDEO_PLAYER_CONTROLS_TIMESKIP_ICON_TOOLTIP', 'COMPONENT_VIDEOPLAYER_SKIP_STEP_LABEL'], 'Skip by')}
        inverted
      />
      <ControlButton
        buttonText={forwardButtonText}
        iconName={'step-forward'}
        action={'skip'}
        onClick={handleSkipForwards}
      />
      <Popup
        mountNode={popupMountNode}
        context={{
          getBoundingClientRect: () => menuPopupPosition
        }}
        on='click'
        open={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        inverted
      >
        <Menu
          inverted
          items={stepSizeList}
          onItemClick={(e, data) => {
            handleSizeChange(e, data.value);
            setIsPopupOpen(false);
          }}
          secondary
          vertical
        />
      </Popup>
    </>
  );
};

export default memo(withTranslation()(TimeSkipControls));
