import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Form,
  Header,
  List,
  Modal,
  Placeholder,
} from "semantic-ui-react";
import DateTimeService from "../services/DateTimeService";
import { withTranslation } from "react-i18next";
import DisplayQuestion from "./display/DisplayQuestion";
import Questionnaire from "./Questionnaire";
import { useHistory } from "react-router";
import i18next from "i18next";
import QuestionnaireContext from "../context/QuestionnaireContext";
import { AuthoriseHelpers, QUESTION_TYPES, typeHelper } from "atom5-branching-questionnaire";
import QuestionHelper from "../helpers/QuestionHelper";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import AuthService from "../services/AuthService";
import API_QUESTIONNAIRE_DEFINITION_TYPES from "../constants/API_QUESTIONNAIRE_DEFINITION_TYPES";
import ConfigContext from "../context/ConfigContext";
import EXPORT_CONFIG_VALUES from "../constants/EXPORT_CONFIG_VALUES";
import QUESTION_WORKFLOW_TASK_BEHAVIOUR
  from "atom5-branching-questionnaire/src/constants/QUESTION_WORKFLOW_TASK_BEHAVIOUR";
import questionnaireWorkflowHelper from "../helpers/questionnaireWorkflowHelper";

const ReadOnlyQuestionnaire = ({
  definition: passedDefinition,
  questionnaire,
  onPageChange,
  subjectId,
  subjectCode,
  permissions = [],
  isStaff,
  t,
  shouldShowTitle = true,
  shouldShowQuestionnaireMeta = true
}) => {
  const history = useHistory();
  const returnTo = useContext(QuestionnaireContext)?.returnTo;

  const config = useContext(ConfigContext);

  const [isExportingPDF, setIsExportingPDF] = useState(false);

  const showQuestionnaireId =
      config?.ui?.questionnaires?.showQuestionnaireId || false;

  const buildModifiedDefinition = () => {
    if (passedDefinition?.questions == null) {
      return passedDefinition;
    }
    // FUTURE: Refactor if more QUESTION_TYPES modify the readonly questionnaire definition ahead of render
    return buildModifiedDefinitionForAuthorise();
  }

  const buildModifiedDefinitionForAuthorise = () => {
    try {
      const authoriseQuestion = passedDefinition.questions.find(q => q.type === QUESTION_TYPES.AUTHORISE);
      if (authoriseQuestion == null) {
          return passedDefinition;
      }

      const type = authoriseQuestion.config?.type;
      switch (type) {
        case AuthoriseHelpers.ModalAuthorise.NAME:
          const includeQuestionCodes = AuthoriseHelpers.ModalAuthorise.getIncludeQuestionCodes(authoriseQuestion);
          const authoriseQuestions = AuthoriseHelpers.ModalAuthorise.getAuthoriseModalQuestions(passedDefinition, includeQuestionCodes, authoriseQuestion.code);

          const readonlyQuestions = passedDefinition.questions.map(q => {
            const authoriseQuestion = authoriseQuestions.find(aq => aq.code === q.code);
            if (authoriseQuestion != null) {
                return authoriseQuestion;
            }
            return q;
          });
          return {...passedDefinition, questions: readonlyQuestions};

        default:
          return passedDefinition
      }
    } catch (error) {
      console.error('[ReadOnlyQuestionnaire] Error Getting Modified Definition for AUTHORISE', error);
    }
  }

  const definition = buildModifiedDefinition();

  const onDone = () => {
    if (returnTo) {
      history.push(returnTo);
      return;
    }
    const type = questionnaire.type.toLowerCase();
    history.push("/app/subject/" + type);
  };

  const getListQuestionnaire = (printable) => {
    return (
      <Form>
        {definition.questions.map((question) => {
          let answer = questionnaire.answers[question.code];
          const showOnDashboardConfig = QuestionHelper.getConfigValue(
              question,
              "showOnDashboard",
              undefined
          );
          if (
            [
              QUESTION_TYPES.HEADING,
              QUESTION_TYPES.MARKUP,
              QUESTION_TYPES.PARAGRAPH,
            ].indexOf(question.type) > -1
          ) {
            const renderAs = QuestionHelper.getConfigValue(
              question,
              "renderAs",
              "label"
            );
            if (renderAs === "label") {
              if (typeHelper.parseBool(showOnDashboardConfig)) {
                answer = question.label;
              }
            }
          }

          const hasAnswer = answer !== null && answer !== undefined;

          const task = questionnaireWorkflowHelper.getTaskFromQuestionnaire(questionnaire);
          let taskBehaviourArray = [];
          if(task && question.workflowBehaviour){
            const code = task.code;
            taskBehaviourArray = question.workflowBehaviour.map(wB => {
              const [behaviourTask, behaviour] = wB.behaviour.split("://")
              if(behaviourTask !== code) return null
              return behaviour
            }).filter(wB=>!!(wB))
          }
          if(taskBehaviourArray.includes(QUESTION_WORKFLOW_TASK_BEHAVIOUR.REMOVE)){
            console.error("[A5BQ][BranchingQuestionnaire][shouldQuestionBeVisible] Question which should be removed found at front end, hiding", question)
            return false
          }
          if(taskBehaviourArray.includes(QUESTION_WORKFLOW_TASK_BEHAVIOUR.HIDDEN)){
            return false
          }
          if(taskBehaviourArray.includes(QUESTION_WORKFLOW_TASK_BEHAVIOUR.VIEW)){
            return true
          }

          // hidden by default and no config to show: hide
          if(question.hidden && !typeHelper.parseBool(showOnDashboardConfig)){
            return null;
          }

          // no answer and no config to show: hide
          if(!hasAnswer && !typeHelper.parseBool(showOnDashboardConfig)){
            return null
          }

          const showQueryButtons = [API_QUESTIONNAIRE_DEFINITION_TYPES.DATA, API_QUESTIONNAIRE_DEFINITION_TYPES.SUBJECT_RECORD].indexOf(questionnaire.type.toUpperCase()) >= 0;
          return (
            <DisplayQuestion
              definition={definition}
              question={question}
              answer={answer}
              key={question.code}
              subjectId={subjectId}
              permissions={permissions}
              printable={printable}
              questionnaireId={questionnaire.id}
              showQueryButtons={showQueryButtons}
            />
          );
        })}
      </Form>
    );
  };

  const getInteractiveQuestionnaire = () => {
    return (
      <Questionnaire
        definition={definition}
        questionnaire={questionnaire}
        onPageChange={onPageChange}
        onSubmit={onDone}
        showSubmitAs={i18next.t("GLOBAL_BUTTON_DONE")}
        isReadOnly={true}
      />
    );
  };

  const downloadPDF = async () => {
    const input = document.getElementById("modalQuestionnaire");
    const canvas = await html2canvas(input);

    let imgWidth = 200;
    let imgHeight = (canvas.height * imgWidth) / canvas.width;

    const imgData = canvas.toDataURL("img/png");
    const pdf = new jsPDF("p", "mm", [imgWidth + 10, imgHeight + 10]);

    pdf.addImage(imgData, "PNG", 5, 5, imgWidth, imgHeight);

    const definitionName = definition.label.toLowerCase().replaceAll(" ", "_");
    const completionDate = DateTimeService.build.asDate(
      questionnaire.completionDate
    );

    const subjectCodeForFileName = AuthService.isStaff()
      ? subjectCode + "-"
      : "";
    const fileName =
      subjectCodeForFileName + completionDate + "-" + definitionName + ".pdf";
    pdf.save(fileName);
  };

  useEffect(() => {
    if (isExportingPDF) {
      const doExport = async () => {
        await downloadPDF();
        setIsExportingPDF(false);
      };
      setTimeout(doExport, 3000);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isExportingPDF]);

  const getQuestionnnaireMeta = () => {
    if (!questionnaire) return null;
    return (
        <List size="tiny">
          {showQuestionnaireId && (
            <List.Item>
              <em>
                {t("SUBJECT_QUESTIONNAIRE_ID", "Questionnaire ID")}{": "}{questionnaire.id}
              </em>
            </List.Item>
          )}
          <List.Item>
            <em>
              {t("SUBJECT_QUESTIONNAIRE_META_CREATED")}{" "}
              {DateTimeService.build.asDisplayDateTime(
                questionnaire.creationDate
              )}
            </em>
          </List.Item>
          {questionnaire.deliveryDate && (
            <List.Item>
              <em>
                {t("SUBJECT_QUESTIONNAIRE_META_DELIVERED")}{" "}
                {DateTimeService.build.asDisplayDateTime(
                  questionnaire.deliveryDate
                )}
              </em>
            </List.Item>
          )}
          {questionnaire.completionDate && (
            <List.Item>
              <em>
                {t("SUBJECT_QUESTIONNAIRE_META_COMPLETED")}{" "}
                {DateTimeService.build.asDisplayDateTime(
                  questionnaire.completionDate
                )}
              </em>
            </List.Item>
          )}
          {questionnaire?.latitude && questionnaire?.longitude && (
              <List.Item>
                <em>
                  {t("SUBJECT_QUESTIONNAIRE_META_LOCATION", "Location:")}{" "}
                  {questionnaire.latitude + ', ' + questionnaire.longitude}
                </em>
              </List.Item>
          )}
          {AuthService.isStaff() && questionnaire.questionnaireAuthReference && (
            <List.Item>
              <em>
                {t("SUBJECT_QUESTIONNAIRE_META_COMPLETED_BY", "Completed by:")}{" "}
                {questionnaire.questionnaireAuthReference}
              </em>
            </List.Item>
          )}
        </List>
    );
  };

  const getForm = (renderedQuestionnaire) => {
    const questionnaireMeta = getQuestionnnaireMeta();
    return (
      <div>
        {shouldShowTitle && <Header>{definition.label}</Header>}
        {shouldShowQuestionnaireMeta && questionnaireMeta}
        {renderedQuestionnaire}
      </div>
    );
  };

  const visibleQuestionnaire = getForm(
    isStaff ? getListQuestionnaire(false) : getInteractiveQuestionnaire()
  );
  const modalQuestionnaire = getForm(getListQuestionnaire(true));

  const allowExportToPDFConfig =
    definition.config?.allowExportToPDF?.toUpperCase();

  const allowStaffToExport =
    allowExportToPDFConfig === EXPORT_CONFIG_VALUES.BOTH ||
    allowExportToPDFConfig === EXPORT_CONFIG_VALUES.STAFF;
  const allowSubjectToExport =
    allowExportToPDFConfig === EXPORT_CONFIG_VALUES.BOTH ||
    allowExportToPDFConfig === EXPORT_CONFIG_VALUES.SUBJECT;

  const allowExportToPDF = AuthService.isStaff()
    ? allowStaffToExport
    : allowSubjectToExport;

  const exportButtonKey =
    "SUBJECT_QUESTIONNAIRE_EXPORT_PDF_" +
    (AuthService.isStaff() ? "STAFF" : "SUBJECT");

  return (
    <>
      <Modal style={{ padding: 30 }} open={isExportingPDF}>
        <h2>{t("SUBJECT_QUESTIONNAIRE_EXPORTING")}</h2>
        <Placeholder fluid style={{ marginBottom: 20 }}>
          <Placeholder.Line />
        </Placeholder>
        <div id="modalQuestionnaire">
          <h1>
            {t("SUBJECT_LIST_TABLE_HEADER_SUBJECT_CODE")}: {subjectCode}
          </h1>
          {modalQuestionnaire}
        </div>
      </Modal>
      {visibleQuestionnaire}
      {allowExportToPDF && (
        <Button
          primary
          style={{ marginTop: 20 }}
          onClick={() => setIsExportingPDF(true)}
        >
          {t(exportButtonKey)}
        </Button>
      )}
    </>
  );
};

export default withTranslation()(ReadOnlyQuestionnaire);
