import {QUESTION_TYPES, typeHelper} from "atom5-branching-questionnaire";
import QUESTION_WORKFLOW_TASK_BEHAVIOUR
  from "atom5-branching-questionnaire/src/constants/QUESTION_WORKFLOW_TASK_BEHAVIOUR";

// TODO: remove hide answers, should be based only on questionnaire
const shouldDisplayInDataTable = (task, question, hideAnswers) => {
  let taskBehaviourArray = [];
  if(task && question.workflowBehaviour){
    const code = task.code;
    taskBehaviourArray = question.workflowBehaviour.map(wB => {
      const [behaviourTask, behaviour] = wB.behaviour.split("://")
      if(behaviourTask !== code) return null
      return behaviour
    }).filter(wB=>!!(wB))
  }
  if(taskBehaviourArray.includes(QUESTION_WORKFLOW_TASK_BEHAVIOUR.REMOVE)){
    console.error("[A5BQ][BranchingQuestionnaire][shouldQuestionBeVisible] Question which should be removed found at front end, hiding", question)
    return false
  }
  if(taskBehaviourArray.includes(QUESTION_WORKFLOW_TASK_BEHAVIOUR.HIDDEN)){
    return false
  }
  if(taskBehaviourArray.includes(QUESTION_WORKFLOW_TASK_BEHAVIOUR.VIEW)){
    return true
  }

  if ( typeof (question?.config?.showOnDashboardOverrideRestrictedAccess) != "undefined") {
    if (typeHelper.parseBool(question?.config?.showOnDashboardOverrideRestrictedAccess)){
      return typeHelper.parseBool(question?.config?.showOnDashboardOverrideRestrictedAccess);
    }
  }

  if (hideAnswers) {
    return false;
  }

  if ( typeof (question?.config?.showOnDashboard) != "undefined") {
    return typeHelper.parseBool(question?.config.showOnDashboard);
  }

  if (question.hidden) {
    return false;
  }

  switch (question.type) {
    case QUESTION_TYPES.HEADING:
    case QUESTION_TYPES.PARAGRAPH:
    case QUESTION_TYPES.MARKUP:
    case QUESTION_TYPES.RENDER_IMAGE:
    case QUESTION_TYPES.LINK:
    case QUESTION_TYPES.RENDER_VIDEO:
      return false;
    default:
      return true;
  }
};

export default shouldDisplayInDataTable;