import React, { Component, Fragment } from "react";
import { withTranslation } from "react-i18next";
import { Grid, Menu, Message, Placeholder } from "semantic-ui-react";
import { Link } from "react-router-dom";
import SubjectGraph from "./graphs/SubjectGraph";
import SubjectService from "../../SubjectService";
import GroupPermission from "../../GroupPermission";

class SubjectViewGraphsTab extends Component {
  constructor(props, context) {
    super(props, context);

    this.state = this.initialise();
  }

  initialise = () => {
    SubjectService.getSubjectPermission(this.props.match.params.subjectId).then(
      (permissions) => {
        this.setState({ permissions, loading: false });
      }
    );
    return {
      error: false,
      permissions: [],
      loading: true,
    };
  };

  errorHandler = (error) => {
    console.error(error);
    this.setState({ error: true });
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.match.params.subjectId !== this.props.match.params.subjectId ||
      prevProps.match.params.graph !== this.props.match.params.graph
    ) {
      this.setState(this.initialise());
    }
  };

  render() {
    const { t } = this.props;
    let selectedGraph = "loading";
    if (this.props.match.params.graph) {
      selectedGraph = this.props.match.params.graph;
    } else if (Window.configuration.ui.graphs.length > 0) {
      selectedGraph = Window.configuration.ui.graphs[0];
    }

    const menuItems = Window.configuration.ui.graphs.map((graph) => {
      if (typeof graph === "string" || graph instanceof String) {
        return (
          <Link
            to={
              "/app/subject/" +
              this.props.match.params.subjectId +
              "/tabs/graphs/" +
              graph
            }
            className={"item " + (selectedGraph === graph ? "active" : "")}
            key={graph}
          >
            {t("SUBJECT_GRAPH_" + graph.toUpperCase(), graph)}
          </Link>
        );
      } else {
        return (
          <Link
            to={
              "/app/subject/" +
              this.props.match.params.subjectId +
              "/tabs/graphs/daily/" +
              graph.questionnaire +
              "/" +
              graph.daily +
              "/" +
              graph.count
            }
            className={"item " + (selectedGraph === graph ? "active" : "")}
            key={graph.label}
          >
            {t(graph.label.toUpperCase())}
          </Link>
        );
      }
    });

    const hasViewSubjectDataPermission = this.state.permissions.includes(GroupPermission.VIEW_SUBJECT_DATA);

    return (
      <Fragment>
        {this.state.loading && (
          <Placeholder>
            <Placeholder.Header image>
              <Placeholder.Line />
              <Placeholder.Line />
            </Placeholder.Header>
          </Placeholder>
        )}

        {!this.state.loading &&
          !hasViewSubjectDataPermission && (
            <>
              <Message>{t("SUBJECT_VIEW_MISSING_DATA_PERMISSION")}</Message>
            </>
          )}

        {!this.state.loading && hasViewSubjectDataPermission && (
            <Grid>
              <Grid.Column width={3}>
                <Menu fluid vertical tabular>
                  {menuItems}
                </Menu>
              </Grid.Column>
              <Grid.Column width={13}>
                <Fragment>
                  {selectedGraph === "daily" && (
                    <SubjectGraph
                      subjectId={this.props.match.params.subjectId}
                      graph={selectedGraph}
                      code={this.props.match.params.code}
                      metric={this.props.match.params.metric}
                      range={this.props.match.params.range}
                    />
                  )}
                  {selectedGraph !== "daily" && (
                    <SubjectGraph
                      subjectId={this.props.match.params.subjectId}
                      graph={selectedGraph}
                    />
                  )}
                </Fragment>
              </Grid.Column>
            </Grid>
          )}
      </Fragment>
    );
  }
}

export default withTranslation()(SubjectViewGraphsTab);
