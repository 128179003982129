import QUESTIONNAIRE_COLUMN_KEYS from "../../../constants/QUESTIONNAIRE_COLUMN_KEYS";
import ConfigService from "../../../services/ConfigService";

export default (key, definition) => {
  switch (key) {
    case QUESTIONNAIRE_COLUMN_KEYS.TOTAL_SCORE:
      return !definition.config || !definition.config.hideTotalScore;
    case QUESTIONNAIRE_COLUMN_KEYS.COMPLETED_BY:
      return ConfigService.shouldShowQuestionnaireCompletedByColumn();
    case QUESTIONNAIRE_COLUMN_KEYS.COMPLETED_DATE:
      return ConfigService.shouldShowQuestionnaireDeliveredDateColumn()
    case QUESTIONNAIRE_COLUMN_KEYS.COMPLETED_LOCATION:
      return ConfigService.shouldShowQuestionnaireLocationColumn();
    case QUESTIONNAIRE_COLUMN_KEYS.DELIVERED_DATE:
      return ConfigService.shouldShowQuestionnaireDeliveredDateColumn();
    case QUESTIONNAIRE_COLUMN_KEYS.QUESTIONNAIRE_ID_ENCODED:
      return definition?.config?.encodeQuestionnaireId;
    case QUESTIONNAIRE_COLUMN_KEYS.QUESTIONNAIRE_ID:
      return ConfigService.shouldShowQuestionnaireIdColumn();
    case QUESTIONNAIRE_COLUMN_KEYS.CREATION_TRIGGER:
      return ConfigService.shouldShowQuestionnairesTriggerColumn();
    case QUESTIONNAIRE_COLUMN_KEYS.CREATED_DATE:
      return true;
    default:
      return true
  }
}